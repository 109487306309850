import { useCallback, useEffect, useRef, useState } from "react";
import {
  drawDimLines,
  drawDimNumbers,
  getDimLineColors,
} from "../utility/imageDimension";

import {
  drawLine,
  drawAnnotationPoint_v2,
  drawColorbarToImage,
  createGdraient,
  drawMeasurements,
  drawIcon,
  drawMeasurementPointRect,
  drawImageBorder,
  getMeasurementBoxHeight,
  drawFreeText,
  ANNOTATION_POINT_CONFIG,
  drawSegmentationLabelToImage,
  calcSegmentationLabelMetrics,
  drawDashedRect,
  drawMeasurementPointCircle,
  drawSegmentationLabelWithActiveLayerToImage,
  drawCross,
  drawIntensityValues,
  displayVolumeEstimationValue,
  drawMeasurementPointTriangle,
  drawDot,
  DRAW_CONFIG,
} from "../utility/imageAux";

import * as THREE from "three";

import deleteIconPath from "../UI/Icons/delete.svg";
import deleteImageIconPath from "../UI/Icons/delete_image.svg";
import expandModalityIconPath from "../UI/Icons/expand_modality.svg";
import collapseModalityIconPath from "../UI/Icons/collapse_modality.svg";
import twoPointsDistanceMeaseurementIconPath from "../UI/Icons/two_points_distance_measurement.svg";
import volumeEstimationIconPath from "../UI/Icons/volume_estimation.svg";
import { create2DSimpleImageScene } from "./ThreeUtils";
import style from "./useImage.module.css";
import Hammer from "hammerjs";
import useStateRef from "../../../dermuscomponents/src/hooks/useStateRef";
import { Box } from "@mui/material";
import { DataTexture, RedFormat, RGBAFormat } from "three";
import { secondary80, error40, neutral40, neutralwhite } from "../../Themes/dermusTheme";
import { nextLetter } from "../utility/utility";
import SegmentationLabel from "../UI/Labels/SegmentationLabel";
import { SEGMENTATION_LAYER_ENUM } from "../Constants/Constants";
import { ShaderMaterial } from "three";
import { FLOW_INTENSITY_LOWER_CASE, INTENSITY, RGB } from "../Constants/Message";
import saveAs from "file-saver";
import { v4 as uuidv4 } from 'uuid';


export const MODE = {
  VOLUME_ESTIMATION: "VOLUME_ESTIMATION",
  THICKNESS_MEASUREMENT: "THICKNESS_MEASUREMENT",
  ANNOTATION: "ANNOTATION",
  FREE_TEXT: "FREE_TEXT",
}
export const ANNOTAION_LABEL_TYPE = {
  MAGNIFIER: "MAGNIFIER",
  RECT: "RECT",
  CROSS: "CROSS",
  DOT: "DOT",
}
export const PIXEL_INTESITY_TYPE = {
  RGB: "RGB",
  INTENSITY_R: "INTENSITY_R",
  INTENSITY_RG: "INTENSITY_RG",
}
export const DEFAULT_CONFIG = {}
DEFAULT_CONFIG[MODE.THICKNESS_MEASUREMENT] = { enable: true, editable: true, maxNum: 3, colors: ["#f55742", "#6572c9", "#649651"], showDistance: true, directedLine: false, fixDistance: null };
DEFAULT_CONFIG[MODE.VOLUME_ESTIMATION] = { enable: true, editable: true, maxNum: 2, colors: ["#e1465e", "#45b6ea"], directedLine: false, fixDistance: null, drawIcon: true };
DEFAULT_CONFIG[MODE.ANNOTATION] = { enable: true, editable: true, maxNum: 7, overWriteLast: false, label: ANNOTAION_LABEL_TYPE.MAGNIFIER, showPixelIntensity: { state: false, type: PIXEL_INTESITY_TYPE.RGB }, labelConfig: { rectW: 30, rectH: 100/*px*/ } };
DEFAULT_CONFIG[MODE.FREE_TEXT] = { enable: true, editable: true };

//Optical image default config
export const OPT_CONFIG = Object.assign({}, DEFAULT_CONFIG);
OPT_CONFIG[MODE.VOLUME_ESTIMATION] = { enable: true, editable: true, maxNum: 1, colors: ["#62bc92"], directedLine: false, fixDistance: null, drawIcon: false };


export const OVERLAY_TYPE = {
  SEGMENTATION: 0,
  LINE_DOPPLER: 1
}

export const MESSAGES = {
  REACH_MAX_NUM_LIMIT: 1
}


export const LABEL_CONFIG = {
  lineWidth: 4,
  color: error40,
  fontSize: 30,
  thickLengths: { mm5: 25, mm1: 10 },
}

export const LABEL_CONFIG_WHITE = {
  lineWidth: 4,
  color: neutralwhite,
  fontSize: 30,
  thickLengths: { mm5: 25, mm1: 10 }
}
const EDITABLE_RADIUS = 0.03;
const ZOOM_SCALE_FACTOR = 1.1;
export const DEFAULT_ZOOM = { x: 0.5, y: 0.5, scale: 1.0 }
export const DEFAULT_CROPPING = { x: 1, y: 1 }



export const IMAGE_MARGIN = 3;

const COLOR_BAR_WIDTH_PERCENT = 0.8
const COLOR_BAR_HEIGHT_PERCENT = 0.01
const COLOR_BAR_Y_PERCENT = 0.90

const DELETE_ICON_MARGIN = 10;
const DELETE_ICON_SIZE = 100;

const MODALITY_HIGHLIGHT_ICON_MARGIN_RATIO = 18;
const MODALITY_HIGHLIGHT_ICON_SIZE_RATIO = 10;
const MODALITY_HIGHLIGHT_ICON_SIZE_MAX = 60;

const MEASUREMENT_POSITION_Y_PERCENT = 0.988;
const MEASUREMENT_POSITION_X_PERCENT = 0.985;
const MEASUREMENT_TEXT_PADDING = 10;
const MEASUREMENT_BOX_PADDING = 15;
const FONT_SIZE = 28;
const INTESNITY_FONT_SIZE = 24;

export const DRAW_LINE_WIDTH = 4
export const DRAW_ANNOTATION_RADIUS = 25
export const DRAW_ANNOTATION_RADIUS_IN = 4
export const DRAW_ANNOTATION_RADIUS_POINT = 10
const DRAW_MEASUREMENT_RECT_SIZE = 20;

export const DEFAULT_LAYER_ORDER = {
  EVENT: 10,
  LABEL: 8,
  EXTRA: 6,
  DIM: 4,
  OVERLAY: 2,
  IMAGE: 0
}

export const DOPPLER_LAYER_ORDER = {
  EVENT: 10,
  LABEL: 8,
  EXTRA: 6,
  DIM: 2,
  OVERLAY: 4,
  IMAGE: 0
}

/**
 * Calculate 0-1 coord zoomed system to canvas size
 * @param {Object} point {x: x coord, y: y coord} in relative zoomed coord system (0-1)
 * @param {Object} actZoom act zoom state {x, y, scale} see: zoom
 * @param {Canvas} canvas canvas  
 * @returns absolute x-y
 */
export const relativeToAbolutePointPosition = (point, actZoom, canvas) => ({
  x: (DEFAULT_ZOOM.x + (point.x - actZoom.x) * actZoom.scale) * canvas.width,
  y: (DEFAULT_ZOOM.y + (point.y - actZoom.y) * actZoom.scale) * canvas.height,
});
/**
 * Calculate absolute position to zoomed relative system
 * @param {Object} point {x: x coord, y: y coord} in abs zoomed coord system
 * @param {Object} actZoom act zoom state {x, y, scale} see: zoom
 * @param {Canvas} canvas canvas  
 * @returns realitve x-y
 */
const absoluteToRelativePointPosition = (point, actZoom, canvas) => ({
  x: actZoom.x + (-DEFAULT_ZOOM.x + point.x / canvas.clientWidth) / actZoom.scale,
  y: actZoom.y + (-DEFAULT_ZOOM.y + point.y / canvas.clientHeight) / actZoom.scale,
})

const INIT_COLORS = {}
INIT_COLORS[MODE.ANNOTATION] = []
INIT_COLORS[MODE.THICKNESS_MEASUREMENT] = []
INIT_COLORS[MODE.VOLUME_ESTIMATION] = []


const getMaxMinYPan = (newY, scale, height, maxYTop, maxYBottom, croppingPercent) => {
  //const a = Math.min(Math.max(newY, (-0.5 * height + scaledHeight / 2) / height), (height + height * 0.5 - scaledHeight / 2) / height)
  const scaledHeight = height / scale
  return Math.min(Math.max(newY,
    -scaledHeight / height * (0.5 - (maxYTop))),
    1 / croppingPercent.y + scaledHeight / height * (0.5 - maxYBottom))
}
const getMaxMinXPan = (newX, scale, width, maxXLeft, maxXRight, croppingPercent) => {
  //const a = Math.min(Math.max(newY, (-0.5 * height + scaledHeight / 2) / height), (height + height * 0.5 - scaledHeight / 2) / height)
  const scaledWidth = width / scale
  return Math.min(Math.max(newX,
    -scaledWidth / width * (0.5 - (maxXLeft))),
    1 / croppingPercent.x + scaledWidth / width * (0.5 - maxXRight))
}

const getGPUZoomParams = (croppingPercent, actZoom, mirror = false) => {
  const newW = (2 * croppingPercent.x) / actZoom.scale
  const newH = (2 * croppingPercent.y) / actZoom.scale
  const x = (2 * (actZoom.x * croppingPercent.x) - 1) - newW / 2
  const y = mirror ? (2 * (actZoom.y * croppingPercent.y) - 1) - newH / 2
    : (2 * (1 - (actZoom.y * croppingPercent.y)) - 1) - newH / 2
  return { newW, newH, x, y }
}

const getNextAnnotationLabel = (points, isText) => {
  const actLabels = points.map(item => item[0]?.label)
  if (actLabels.length === 0) {
    return isText ? "a" : 1
  }
  let nextItem = isText ? nextLetter : (c) => c + 1

  let nextChar = isText ? "a" : 1
  while (true) {
    if (!actLabels.includes(nextChar)) {
      return nextChar
    }
    nextChar = nextItem(nextChar)
  }
}

const getTopRightIconSizeAndMargin = (canvas) => [Math.min(Math.round(canvas.height / MODALITY_HIGHLIGHT_ICON_SIZE_RATIO), MODALITY_HIGHLIGHT_ICON_SIZE_MAX), Math.round(canvas.height / MODALITY_HIGHLIGHT_ICON_MARGIN_RATIO)]

const useImage = (
  { initOverlayOpacity,
    initTargetSize,
    initSizePxPMM,
    initCanvasSize,
    initZoomOption,
    initExtraDrawOnDimLayer,
    initCroppingPercent,
    initShowDim,
    initGpuFormat = RGBAFormat,
    initGpuType = THREE.UnsignedByteType,
    modesConfig = null,
    shader = null,
    overlayShader = null,
    updateUnifrom = () => { },
    updateOverlayUnifrom = () => { },
    colorbars = [],//0 - colormap image, 1 - gray image, 2 - overlay colormap
    handleTMVEswitch = () => { },
    onImageClick = () => { },
    onImageExpandClick = () => { },
    initApplyColormap = true,
    initShowColorbar = false,
    initDefaultZoom = DEFAULT_ZOOM,
    invertedAnnotationColor = false,
    extraGPUParamsInit = {},
    drawWithGuideLine = true,
    modality,
    initGrayedOut = 0,
    addMeasurementPointCondition = () => true,
    addAnnotationPointCondition = () => true,
    dynamicRulerColoring = false,
    expandable = true,
    imageMargin = IMAGE_MARGIN,
    overlayTypeInit = OVERLAY_TYPE.SEGMENTATION,
    initLayerOrder = DEFAULT_LAYER_ORDER
  }
) => {
  //OVerlayType
  const [overlayType, setOverlayType, overlayTypeRef] = useStateRef(overlayTypeInit ?? OVERLAY_TYPE.SEGMENTATION)
  //Layer order
  const [layerOrder, setLayerOrder] = useState(initLayerOrder ?? DEFAULT_LAYER_ORDER)
  // Three instance
  const threeRef = useRef();
  // Three instance
  const threeRefOverlay = useRef();
  //Stored image to get pixel intesity values
  const storedLastImageRef = useRef()
  //GPU format and Type
  const [, setGpuFormat, gpuFormatRef] = useStateRef(initGpuFormat)
  const [, setGpuType, gpuTypeRef] = useStateRef(initGpuType)
  //Show dim
  const [showDim] = useState(initShowDim ?? true)
  //Show overlay
  const [showOverlay, setShowOverlay, showOverlayRef] = useStateRef(false);
  //Show overlay
  const [showModify, setShowModify, showModifyRef] = useStateRef(false);
  //overlay opacity
  const [overlayOpacity] = useState(initOverlayOpacity || 0.5)
  //gray out 
  const [grayedOut, setGrayedOut] = useState(initGrayedOut)
  //Modes of display
  const [modesOfDisplay, setModesOfDisplay, modesOfDisplayRef] = useStateRef([])
  //Act mode
  const [actMode, setActMode, actModeRef] = useStateRef(null)
  //Condif
  const [configs, setConfigs, configsRef] = useStateRef(modesConfig || DEFAULT_CONFIG)
  //Measurement points
  const [measurementPoints, setMeasurementPoints, measurementPointsRef] = useStateRef([]);
  //Volume estimation points
  const [volumeEstimationPoints, setVolumeEstimationPoints, volumeEstimationPointsRef] = useStateRef([]);
  //Annotations
  const [annotationPoints, setAnnotationPoints, annotationPointsRef] = useStateRef([]);
  //Free text
  const [freeText, setFreeText, freeTextRef] = useStateRef({});
  //Points
  const [points, setPoints, pointsRef] = useStateRef([]);
  //Messages (such as too many annotation points and others...)
  const [message, setMessage] = useState(null);
  //Canvas tag reference of image (with cropping)
  const canvasImageRef = useRef(null);
  //Canvas tag reference of overlay (with cropping)
  const canvasOverlayRef = useRef(null);
  //Canvas tag reference of dimension (with cropping)
  const canvasDimensionRef = useRef(null);
  //Canvas tag reference of extradraw (with cropping)
  const canvasExtraDrawRef = useRef(null);
  //Event div
  const divEventRef = useRef(null)
  //Target size (with cropping)
  const [targetSize, setTargetSize, targetSizeRef] = useStateRef(initTargetSize.width ? initTargetSize : { width: 0 });
  //Real image size in mm (with cropping), NEVER SET DIRECTLY
  const [sizeMM, setSizeMM, sizeMMRef] = useStateRef(null);
  //Real image size in px/mm
  const [sizePxPMM, setPxPMM, sizePxPMMRef] = useStateRef(initSizePxPMM);
  //Image size in px, it is depend on sizePxPMM and sizeMM, NEVER SET DIRECTLY
  const [sizeInPx, setSizeInPx, sizeInPxRef] = useStateRef({ width: 0, height: 0 })
  //Scale/zoom
  const [defaultZoom, setDefaultZoom] = useState(initDefaultZoom)
  const [zoom, setZoom, zoomRef] = useStateRef(defaultZoom);
  //Enable zoom
  const [zoomOption] = useState(initZoomOption);
  //Image to display
  const [imageToDisplay, setImageToDisplay, imageToDisplayRef] = useStateRef(null);
  //Overlay to display
  const [overlayToDisplay, setOverlayToDisplay, overlayToDisplayRef] = useStateRef(null);
  //Drawing function on dimension canvas called after dimension is drawed
  const [extraDrawOnDimLayer, setExtraDrawOnDimLayer] = useState(
    () => (ctx, sizeMM, zoom, croppingPercent, sizeInPx, sizePxPMM, withExtraDraw, withGuideLine, otherModalityZoom, configs) => initExtraDrawOnDimLayer(ctx, sizeMM, zoom, croppingPercent, sizeInPx, sizePxPMM, withExtraDraw, withGuideLine, otherModalityZoom, configs)
  );
  //Last mouse down position
  const [mouseDownPosition, setMouseDownPosition] = useState({ x: 0, y: 0 });
  // current Mouse Position for second thickness point
  const [, setCurrentMousePosition, currentMousePositionRef] = useStateRef(null);
  // current vertical distance - thickness measurement
  const [currentDistances, setCurrentDistances, currentDistancesRef] = useStateRef([]);
  // current volume estimation
  const [currentVEDistances, setCurrentVEDistances, currentVEDistancesRef] = useStateRef([]);
  //Crop image in percent
  const [croppingPercent, setCroppingPercent, croppingPercentRef] = useStateRef(initCroppingPercent ?? DEFAULT_CROPPING)
  //index of highlighted point
  const [highlightedPoint, setHighlightedPoint, highlightedPointRef] = useStateRef(null)
  //move highlighted point
  const [moveHighlightedPoint, setMoveHighlightedPoint, moveHighlightedPointRef] = useStateRef(false)
  //canvas size
  const [canvasSizeVar, setCanvasSizeVar] = useState();
  //First render
  const [firstRender, setFirstRender, firstRenderRef] = useStateRef(false)
  // Show colorbar
  const [showColorbar, setShowColorbar] = useState(initShowColorbar)
  //Colorbar gradients
  const [colorbarGradients, setColorbarGradients] = useState([])
  //Active border or not
  const [isActiveBorder, setIsActiveBorder] = useState(false)
  //Delete icon
  const [deleteIcon, setDeleteIcon, deleteIconRef] = useStateRef()
  //Delete image icon
  const [deleteImageIcon, setDeleteImageIcon] = useState()
  //Delete image modal is active
  const [deleteImageModalIsActive, setDeleteImageModalIsActive] = useState(false)
  //ExpandModalityIcon
  const [expandModalityIcon, setExpandModalityIcon] = useState()
  //CollapseModalityIcon
  const [collapseModalityIcon, setCollapseModalityIcon] = useState()
  //VolumeEstimationIcon
  const [volumeEstimationIcon, setVolumeEstimationIcon] = useState()
  //TwoPointsDistanceMeaseurementIcon
  const [twoPointsDistanceMeaseurementIcon, setTwoPointsDistanceMeaseurementIcon] = useState()
  //Mouse middle button pan
  const [mouseMiddlePan, setMouseMiddlePan, mouseMiddlePanRef] = useStateRef(false)
  //Mouse contorl pan
  const [controlPan, setControlPan, controlPanRef] = useStateRef(false)
  //Wheeling
  const [wheeling, setWheeling, wheelingRef] = useStateRef(false)
  //Wheeling timeout id
  const [, , wheelingTiemrIdRef] = useStateRef(null)
  //Pinching or not
  const [pinching, setPinching, pinchingRef] = useStateRef(false)
  //Measurement colors
  const [measuermentColors, setMeasuermentColors, measuermentColorsRef] = useStateRef({ colors: [], options: [...(configs[MODE.THICKNESS_MEASUREMENT]?.colors ?? [])] })
  //Volume estimation colors
  const [volumeEstimationColors, setVolumeEstimationColors, volumeEstimationColorsRef] = useStateRef({ colors: [], options: [...(configs[MODE.VOLUME_ESTIMATION]?.colors ?? [])] })
  //Apply colormap
  const [applyColormap, setApplycolormap, applyColormapRef] = useStateRef(initApplyColormap)
  //Adaptive colors
  const [, , adaptiveColorsRef] = useStateRef(null);
  //Measuerment box start pos on original(canvasExtraDrawRef) canvas
  const [measuermentDrawStartOnDisplay, setMeasuermentDrawStartOnDisplay] = useState(null)
  //Extra gpu param
  const [extraGPUParams, setExtraGPUParams, extraGPUParamsRef] = useStateRef(extraGPUParamsInit)

  //Overlay active layer
  const [activeLayerOfOverlay, setActiveLayerOfOverlay, activeLayerOfOverlayRef] = useStateRef(0)
  //Overlay extra data
  const [overlayExtraData, setOverlayExtraData, overlayExtraDataRef] = useStateRef(null)
  //Dynamic coloring
  const [, setRulerColoring, rulerColoringRef] = useStateRef(dynamicRulerColoring)
  //Modality highlight
  const [modalityHighlight, setModalityHighlight] = useState(false)
  //Thickness measurement vs volume estimation
  //const [thicknessMeasurementVSvolumeEstimation, setThicknessMeasurementVSvolumeEstimation, thicknessMeasurementVSvolumeEstimationRef] = useStateRef(null)
  //Show volume estimation
  const [showVolumeEstimation, setShowVolumeEstimation, showVolumeEstimationRef] = useStateRef(null)

  //Init three js gpu canvas of image
  const initThree = useCallback((di) => {
    const data = gpuTypeRef.current === THREE.UnsignedByteType ? new Uint8Array((gpuFormatRef.current === RedFormat ? 1 : 4) * di.width * di.height) :
      new Float32Array((gpuFormatRef.current === RedFormat ? 1 : 4) * di.width * di.height);
    const dataTexture = new THREE.DataTexture(
      data,
      di.width, di.height,
      gpuFormatRef.current,
      gpuTypeRef.current,
      THREE.UVMapping,
      THREE.ClampToEdgeWrapping,
      THREE.ClampToEdgeWrapping,
      THREE.LinearFilter,
      THREE.LinearFilter
    );
    dataTexture.needsUpdate = true;
    shader.uniforms.tDiffuse.value = dataTexture

    const { renderer, scene, camera, rendererTarget, material, geometry } = create2DSimpleImageScene(canvasImageRef.current, di, shader)

    const pixelBuffer = new Float32Array(4 * di.width * di.height);

    threeRef.current = { renderer, scene, camera, material, rendererTarget, pixelBuffer, geometry };
    threeRef.current.render = () => { threeRef.current.renderer.render(threeRef.current.scene, threeRef.current.camera); };
    threeRef.current.render()
    threeRef.current.initOut = false

    // eslint-disable-next-line
  }, [])
  /**
   * Set image canvas size
   * @param {Number} width width of image canvas
   * @param {Number} height height of image canvas
   */
  const setCanvasSize = useCallback((width, height) => {
    if (canvasImageRef.current?.width !== null && canvasImageRef.current?.width !== undefined
      && canvasImageRef.current?.height !== null && canvasImageRef.current?.height !== undefined &&
      !threeRef.current?.initOut) {
      const ratioX = croppingPercent.x;
      const ratioY = croppingPercent.y;

      const newWidth = Math.round(width * ratioX);
      const newheight = Math.round(height * ratioY);
      if (
        canvasImageRef.current.width !== newWidth ||
        canvasImageRef.current.height !== newheight
      ) {
        canvasImageRef.current.width = newWidth;
        canvasImageRef.current.height = newheight;

        setSizeInPx({ width: newWidth, height: newheight })
        setCanvasSizeVar({ width: newWidth, height: newheight })
        if (sizePxPMMRef.current) {
          setSizeMM(s => {
            if (!s?.width || !s?.height || width / sizePxPMMRef.current?.width * ratioX !== s?.width ||
              height / sizePxPMMRef.current?.height * ratioY !== s?.height) {
              return {
                width: width / sizePxPMMRef.current.width * ratioX,
                height: height / sizePxPMMRef.current.height * ratioY,
                dimension: sizePxPMMRef.current.dimension,
              }
            } else {
              return s
            }
          });
        }
        if (threeRef.current) {
          threeRef.current.renderer.setSize(canvasImageRef.current.width, canvasImageRef.current.height, false);
          if (threeRef.current?.material?.uniforms?.tDiffuse?.value instanceof DataTexture) {
            threeRef.current.material.uniforms.tDiffuse.value = new DataTexture(
              null,
              width,
              height,
              gpuFormatRef.current,
              gpuTypeRef.current,
              THREE.UVMapping,
              THREE.ClampToEdgeWrapping,
              THREE.ClampToEdgeWrapping,
              //THREE.NearestFilter,
              //THREE.NearestFilter
              THREE.LinearFilter,
              THREE.LinearFilter
            );
          }
        } else {
          initThree({ width, height })
        }
      }
    }// eslint-disable-next-line
  }, [croppingPercent, initThree])
  /**
   * Set overlay canvas size
   * @param {Number} width width of overlay canvas
   * @param {Number} height height of overlay canvas
   */
  const setOverlayCanvasSize = useCallback((width, height) => {
    const ratioX = croppingPercent.x;
    const ratioY = croppingPercent.y;
    canvasOverlayRef.current.width = Math.round(width * ratioX);
    canvasOverlayRef.current.height = Math.round(height * ratioY);
  }, [croppingPercent])

  /**
   * Set width and height of dimension canvas and display -> height is calculated based on sizeMM aspect ratio
   * @param {Number} width width of dimension canvas and width of display
   */
  const setDimensionCanvasAndTargetSize = useCallback(({ width }) => {

    storedLastImageRef.current = null
    if (width) {
      if (sizeMM && canvasDimensionRef.current && canvasExtraDrawRef.current && divEventRef.current) {
        const height = (sizeMM.height / sizeMM.width) * width;
        setTargetSize({ width, height });


        const newWidth = 2 * width;
        canvasDimensionRef.current.width = newWidth;
        canvasDimensionRef.current.height = (sizeMM.height / sizeMM.width) * newWidth;
        canvasExtraDrawRef.current.width = newWidth;
        canvasExtraDrawRef.current.height = (sizeMM.height / sizeMM.width) * canvasExtraDrawRef.current.width;

        divEventRef.current.width = newWidth;
        divEventRef.current.height = (sizeMM.height / sizeMM.width) * divEventRef.current.width;


        const ctx = canvasExtraDrawRef.current.getContext("2d");
        setColorbarGradients(colorbars.map(item => {
          if (item.vertical) {
            let [modalityHighlightIconSize, modalityHighlightIconMargin] = getTopRightIconSizeAndMargin(canvasExtraDrawRef.current)
            return createGdraient(ctx, item.colormap, 0, ctx.canvas.height - modalityHighlightIconMargin - modalityHighlightIconSize * 2)
          } else {
            return createGdraient(ctx, item.colormap, ctx.canvas.width * (1 - COLOR_BAR_WIDTH_PERCENT) / 2, ctx.canvas.width * COLOR_BAR_WIDTH_PERCENT)
          }
        }



        ))

        //delete annotation or measurement points
        const imgDelete = new Image()
        imgDelete.onload = () => {
          if (canvasExtraDrawRef?.current?.width) {
            setDeleteIcon({
              img: imgDelete,
              x: (canvasExtraDrawRef.current.width - DELETE_ICON_SIZE - DELETE_ICON_MARGIN) / canvasExtraDrawRef.current.width,
              y: DELETE_ICON_MARGIN / canvasExtraDrawRef.current.height, w: DELETE_ICON_SIZE / canvasExtraDrawRef.current.width,
              h: DELETE_ICON_SIZE / canvasExtraDrawRef.current.height
            })
          }
        }
        imgDelete.src = deleteIconPath

        //delete image(for now 3rd or 4th modality)
        const imgDeleteImage = new Image()
        imgDeleteImage.onload = () => {
          if (canvasExtraDrawRef?.current?.width) {
            setDeleteImageIcon({
              img: imgDeleteImage,
              x: 1 / 2 - DELETE_ICON_SIZE / (2 * canvasExtraDrawRef.current.width),
              y: 1 - (DELETE_ICON_SIZE / canvasExtraDrawRef.current.height),
              w: DELETE_ICON_SIZE / canvasExtraDrawRef.current.width,
              h: DELETE_ICON_SIZE / canvasExtraDrawRef.current.height
            })
          }
        }
        imgDeleteImage.src = deleteImageIconPath

        const imgVolumeEstimation = new Image()
        imgVolumeEstimation.onload = () => {
          if (canvasExtraDrawRef?.current?.width) {
            let [volumeEstimationIconSize, volumeEstimationIconMargin] = getTopRightIconSizeAndMargin(canvasExtraDrawRef.current)
            setVolumeEstimationIcon({
              img: imgVolumeEstimation,
              x: (canvasExtraDrawRef.current.width - volumeEstimationIconSize - volumeEstimationIconMargin) /
                canvasExtraDrawRef.current.width,
              y: volumeEstimationIconMargin / canvasExtraDrawRef.current.height,
              w: volumeEstimationIconSize / canvasExtraDrawRef.current.width,
              h: volumeEstimationIconSize / canvasExtraDrawRef.current.height
            })
          }
        }
        imgVolumeEstimation.src = volumeEstimationIconPath

        const imgTwoPointsDistanceMeasure = new Image()
        imgTwoPointsDistanceMeasure.onload = () => {
          if (canvasExtraDrawRef?.current?.width) {
            let [twoPointDistanceMeasureIconSize, twoPointDistanceMeasureIconMargin] = getTopRightIconSizeAndMargin(canvasExtraDrawRef.current)
            setTwoPointsDistanceMeaseurementIcon({
              img: imgTwoPointsDistanceMeasure,
              x: (canvasExtraDrawRef.current.width - twoPointDistanceMeasureIconSize - twoPointDistanceMeasureIconMargin) /
                canvasExtraDrawRef.current.width,
              y: twoPointDistanceMeasureIconMargin / canvasExtraDrawRef.current.height,
              w: twoPointDistanceMeasureIconSize / canvasExtraDrawRef.current.width,
              h: twoPointDistanceMeasureIconSize / canvasExtraDrawRef.current.height
            })
          }
        }
        imgTwoPointsDistanceMeasure.src = twoPointsDistanceMeaseurementIconPath


        let [modalityHighlightIconSize, modalityHighlightIconMargin] = getTopRightIconSizeAndMargin(canvasExtraDrawRef.current)
        setExpandModalityIcon(prevImg => ({
          img: prevImg?.img,
          x: (canvasExtraDrawRef.current.width - modalityHighlightIconSize - modalityHighlightIconMargin) /
            canvasExtraDrawRef.current.width,
          y: modalityHighlightIconMargin / canvasExtraDrawRef.current.height,
          w: modalityHighlightIconSize / canvasExtraDrawRef.current.width,
          h: modalityHighlightIconSize / canvasExtraDrawRef.current.height
        }))
        setCollapseModalityIcon(prevImg => ({
          img: prevImg?.img,
          x: (canvasExtraDrawRef.current.width - modalityHighlightIconSize - modalityHighlightIconMargin) /
            canvasExtraDrawRef.current.width,
          y: modalityHighlightIconMargin / canvasExtraDrawRef.current.height,
          w: modalityHighlightIconSize / canvasExtraDrawRef.current.width,
          h: modalityHighlightIconSize / canvasExtraDrawRef.current.height
        }))



      } else {
        setTargetSize({ width });
      }
    }
    else if (width === 0) {
      setTargetSize({ width });
    }

    // eslint-disable-next-line 
  }, [sizeMM, modalityHighlight, expandModalityIcon, collapseModalityIcon])


  const getFixDistancePoint = (origPoint, mousePoint, fd, actZoom, canvas) => {
    const p0 = relativeToAbolutePointPosition(origPoint, actZoom, canvas)
    const pm = relativeToAbolutePointPosition(mousePoint, actZoom, canvas)
    const directionVector = { x: pm.x - p0.x, y: pm.y - p0.y }
    const directionVectorLength = Math.hypot(directionVector.x, directionVector.y)
    const normDirVec = { x: directionVector.x / directionVectorLength, y: directionVector.y / directionVectorLength }
    const lengthInPx = fd * (canvas.width / sizeMMRef.current.width)
    const newP = { x: p0.x + normDirVec.x * lengthInPx, y: p0.y + normDirVec.y * lengthInPx }
    return newP
  }
  /**
   * draw point on canvas
   * @param {Canvas} canvas canvas to draw
   * @param {{x, y}} actPoints points to draw
   * @param {{scale, x, y}} actZoom act zoom properties
   * @param {Boolean} drawCurrentMousePosition draw current mouse position
   * @param {Boolean} connectLast2Points draw line between 2last point
   */
  const drawPoints = useCallback((actPoints, canvas, actZoom, mode) => {
    const ctx = canvas.getContext("2d");
    let drawAnnotationPointInFunc = () => { }
    switch (mode) {
      case MODE.VOLUME_ESTIMATION:
        drawAnnotationPointInFunc = (x, y, ctx, config, angle) => {
          if ((config.even || !configsRef.current[mode]?.directedLine) && !isNaN(angle)) {
            drawMeasurementPointTriangle(x, y, ctx, config, angle)
          } else {
            drawMeasurementPointCircle(x, y, ctx, config, DRAW_MEASUREMENT_RECT_SIZE)
          }
        }
        break;
      case MODE.THICKNESS_MEASUREMENT:
        drawAnnotationPointInFunc = (x, y, ctx, config) => {
          if (config.even || !configsRef.current[mode]?.directedLine) {
            drawMeasurementPointRect(x, y, ctx, config, DRAW_MEASUREMENT_RECT_SIZE)
          } else {
            drawMeasurementPointCircle(x, y, ctx, config, DRAW_MEASUREMENT_RECT_SIZE)
          }
        }
        break;
      case MODE.ANNOTATION:
        if (configsRef.current[mode].label === ANNOTAION_LABEL_TYPE.RECT) {
          const w = configsRef.current[mode]?.labelConfig?.rectW / sizePxPMMRef.current.width * (ctx.canvas.width / sizeMMRef.current.width) * actZoom.scale
          const h = configsRef.current[mode]?.labelConfig?.rectH / sizePxPMMRef.current.height * (ctx.canvas.height / sizeMMRef.current.height) * actZoom.scale
          drawAnnotationPointInFunc = (x, y, ctx, config) => {
            drawDashedRect(ctx, x, y, w, h)
          }
        } else if (configsRef.current[mode].label === ANNOTAION_LABEL_TYPE.CROSS) {
          drawAnnotationPointInFunc = (x, y, ctx, config) => drawCross(x, y, ctx, configsRef.current[mode]?.labelConfig)
        } else if (configsRef.current[mode].label === ANNOTAION_LABEL_TYPE.DOT) {
          drawAnnotationPointInFunc = (x, y, ctx, config) =>
            drawDot(x, y, ctx, DRAW_CONFIG)
        } else {
          drawAnnotationPointInFunc = (x, y, ctx, config) => drawAnnotationPoint_v2(ctx, x, y, config.label, true, invertedAnnotationColor, ANNOTATION_POINT_CONFIG)
        }
        break;
      default:
        break;
    }
    let config = {}
    let angle;
    actPoints.forEach((pointSet, ind) => {
      switch (mode) {
        case MODE.VOLUME_ESTIMATION:
          angle = -Math.atan2((pointSet[0].y - pointSet[1].y), (pointSet[0].x - pointSet[1].x))
          config = { color: volumeEstimationColorsRef.current.colors[ind] }
          break;
        case MODE.THICKNESS_MEASUREMENT:
          config = { color: measuermentColorsRef.current.colors[ind] }
          break;
        case MODE.ANNOTATION:
          if (ind >= configs[mode].maxNum) {
            return
          }
          config = { label: pointSet[0].label }
          break;
        default:
          break;
      }
      pointSet.forEach((point, ind) => {
        const absPos = relativeToAbolutePointPosition(point, actZoom, canvas)
        drawAnnotationPointInFunc(absPos.x, absPos.y, ctx, { ...config, even: (ind + 1) % 2 === 0 }, angle + (ind + 1) * Math.PI);
      })
      angle = NaN

      switch (mode) {
        case MODE.VOLUME_ESTIMATION:
        case MODE.THICKNESS_MEASUREMENT:
          drawLine(ctx,
            relativeToAbolutePointPosition(pointSet[0], actZoom, canvas),
            relativeToAbolutePointPosition(pointSet[1], actZoom, canvas),
            config.color, DRAW_LINE_WIDTH);
          break;
        default:
          break;

      }
    })

    switch (mode) {
      case MODE.VOLUME_ESTIMATION:
      case MODE.THICKNESS_MEASUREMENT:
        const colorActPosition = mode === MODE.THICKNESS_MEASUREMENT ?
          measuermentColorsRef.current.colors[measuermentColorsRef.current.colors.length - 1] :
          volumeEstimationColorsRef.current.colors[volumeEstimationColorsRef.current.colors.length - 1]
        // First point
        if (points[0]) {
          const absPos = relativeToAbolutePointPosition(points[0], actZoom, canvas)

          if (currentMousePositionRef.current) {
            angle = -Math.atan2((points[0].y - currentMousePositionRef.current.y), (points[0].x - currentMousePositionRef.current.x))
          }

          drawAnnotationPointInFunc(absPos.x, absPos.y, ctx, { color: colorActPosition, even: false }, angle + Math.PI);
        }
        const fd = configsRef.current[mode]?.fixDistance || null
        // NO FIX DISTANCE
        if (fd === null) {
          // Mouse position is on image
          if (currentMousePositionRef.current) {
            const absPos = relativeToAbolutePointPosition(currentMousePositionRef.current, actZoom, canvas)
            drawAnnotationPointInFunc(absPos.x, absPos.y, ctx, { color: colorActPosition, even: true }, angle);
          }
          if (currentMousePositionRef.current && points[0]) {
            drawLine(ctx,
              relativeToAbolutePointPosition(currentMousePositionRef.current, actZoom, canvas),
              relativeToAbolutePointPosition(points[0], actZoom, canvas),
              colorActPosition, DRAW_LINE_WIDTH);
          }

        } else {
          // FIX DISTANCE
          if (currentMousePositionRef.current && points[0]) {
            drawLine(ctx,
              getFixDistancePoint(points[0], currentMousePositionRef.current, fd, actZoom, canvas),
              relativeToAbolutePointPosition(points[0], actZoom, canvas),
              colorActPosition, DRAW_LINE_WIDTH);
          } else if (currentMousePositionRef.current) {
            const absPos = relativeToAbolutePointPosition(currentMousePositionRef.current, actZoom, canvas)
            drawAnnotationPointInFunc(absPos.x, absPos.y, ctx, { color: colorActPosition, even: true });
          }
        }
        break;
      case MODE.ANNOTATION:
        break;
      default:
        break;

    }
    // eslint-disable-next-line
  }, [configs, points])

  //Draw dimension
  const drawDimensions = useCallback((canvas = canvasDimensionRef.current, actZoom = zoom, cropPerc = croppingPercent, clear = true, withDimension = true, recalcAdaptiveColors = false) => {

    if (threeRef?.current && canvas && actZoom && sizeMMRef.current) {
      let xMult = 1, yMult = 1;
      if (croppingPercentRef.current.x !== cropPerc.x) {
        xMult = 1 / croppingPercentRef.current.x
      }
      if (croppingPercentRef.current.y !== cropPerc.y) {
        yMult = 1 / croppingPercentRef.current.y
      }
      if (rulerColoringRef.current && (recalcAdaptiveColors || adaptiveColorsRef.current === null)) {
        const rt = threeRef.current.isComposer ? threeRef.current.composer.renderTarget2 : threeRef.current.rendererTarget

        const { x, y, newW, newH } = getGPUZoomParams(cropPerc, actZoom, true)

        if (!threeRef.current.isComposer) {
          threeRef.current.renderer.setRenderTarget(rt)
          threeRef.current.material.uniforms.upsidedown.value = false
          threeRef.current.material.uniformsNeedUpdate = true;
        } else {
          threeRef.current.composer.renderToScreen = false
          if (threeRef.current.composer.passes[threeRef.current.composer.passes.length - 1].material.uniforms?.upsidedown) {
            threeRef.current.composer.passes[threeRef.current.composer.passes.length - 1].material.uniforms.upsidedown.value = false
            threeRef.current.composer.passes[threeRef.current.composer.passes.length - 1].material.uniformsNeedUpdate = true;
          }
          if (!threeRef.current.pixelBuffer) {
            threeRef.current.pixelBuffer = new Float32Array(4 * rt.width * rt.height);
          }
        }

        updateUnifrom(threeRef.current, {
          origin: new THREE.Vector2((x + 1) / 2, (y + 1) / 2), size: new THREE.Vector2(newW / 2, newH / 2),
          background: showOverlayRef.current && overlayTypeRef.current === OVERLAY_TYPE.LINE_DOPPLER,
        })
        threeRef.current.render()



        threeRef.current.renderer.readRenderTargetPixels(rt, 0, 0, rt.width, rt.height, threeRef.current.pixelBuffer);

        adaptiveColorsRef.current = getDimLineColors(canvasDimensionRef.current.getContext("2d"), threeRef.current.pixelBuffer, rt.width, rt.height, actZoom, sizeMMRef.current.width * xMult, sizeMMRef.current.height * yMult, LABEL_CONFIG.lineWidth, LABEL_CONFIG.thickLengths);

        if (!threeRef.current.isComposer) {
          threeRef.current.material.uniforms.upsidedown.value = true
          threeRef.current.material.uniformsNeedUpdate = true;
          threeRef.current.renderer.setRenderTarget(null)
          //threeRef.current.render()
        } else {
          if (threeRef.current.composer.passes[threeRef.current.composer.passes.length - 1].material.uniforms?.upsidedown) {
            threeRef.current.composer.passes[threeRef.current.composer.passes.length - 1].material.uniforms.upsidedown.value = true
            threeRef.current.composer.passes[threeRef.current.composer.passes.length - 1].material.uniformsNeedUpdate = true;
          }
          const { x, y, newW, newH } = getGPUZoomParams(cropPerc, actZoom, false)
          updateUnifrom(threeRef.current, { origin: new THREE.Vector2((x + 1) / 2, (y + 1) / 2), size: new THREE.Vector2(newW / 2, newH / 2) })
          threeRef.current.renderer.setRenderTarget(null)
          threeRef.current.composer.renderToScreen = true

        }


      }
      const ctx = canvas.getContext("2d");
      if (ctx) {
        if (clear) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }

        // Draw dimensions
        if (showDim && withDimension) {
          drawDimLines(ctx, actZoom, sizeMMRef.current.width * xMult, sizeMMRef.current.height * yMult, LABEL_CONFIG.lineWidth, adaptiveColorsRef.current, LABEL_CONFIG.thickLengths);
          drawDimNumbers(ctx, actZoom, sizeMMRef.current.width * xMult, sizeMMRef.current.height * yMult, sizeMMRef.current.dimension, LABEL_CONFIG_WHITE.lineWidth, LABEL_CONFIG_WHITE.color, adaptiveColorsRef.current, LABEL_CONFIG.fontSize, LABEL_CONFIG.thickLengths);
        }

      }

    } //eslint-disable-next-line
  }, [
    showDim, sizeMM, zoom, configs,
  ]);

  //Draw dimension
  const drawExtra = useCallback(async (canvas = canvasExtraDrawRef.current, actZoom = zoom, cropPerc = croppingPercent, clear = true, withGuideLine = drawWithGuideLine, withExtraDraw = true, saving = false, forceRecalcImageStore = false) => {
    if (canvas && actZoom && sizeMM) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        if (clear) {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        if (extraDrawOnDimLayer && (withExtraDraw || withGuideLine)) {
          extraDrawOnDimLayer(ctx, sizeMM, actZoom, cropPerc, sizeInPx, sizePxPMM, withExtraDraw, withGuideLine, saving ? DEFAULT_ZOOM : null, configsRef.current);

        }

        if (moveHighlightedPointRef.current) {
          if (deleteIcon?.img) {
            drawIcon(ctx, deleteIcon.img, deleteIcon.x, deleteIcon.y, deleteIcon.w, deleteIcon.h)
          }
        }
        if (expandable && !saving && !moveHighlightedPoint && notMeasureOrVolumeEstimation()) {
          if (!modalityHighlight) {
            if (expandModalityIcon?.img) {
              drawIcon(ctx, expandModalityIcon.img, expandModalityIcon.x, expandModalityIcon.y, expandModalityIcon.w, expandModalityIcon.h)
            }
          } else {
            if (collapseModalityIcon?.img) {
              drawIcon(ctx, collapseModalityIcon.img, collapseModalityIcon.x, collapseModalityIcon.y, collapseModalityIcon.w, collapseModalityIcon.h)
            }
          }
        }

        //TODO
        if (configs[MODE.VOLUME_ESTIMATION]?.drawIcon && !moveHighlightedPointRef.current && !saving && !(showOverlay && overlayType === OVERLAY_TYPE.LINE_DOPPLER)) {

          if (modesOfDisplayRef?.current !== null && modesOfDisplayRef.current.length > 0 && modesOfDisplayRef.current[0] === MODE.THICKNESS_MEASUREMENT) {
            if (volumeEstimationIcon) {
              drawIcon(ctx, volumeEstimationIcon.img, volumeEstimationIcon.x, volumeEstimationIcon.y, volumeEstimationIcon.w, volumeEstimationIcon.h)
            }
          }
          else if (modesOfDisplayRef?.current !== null && modesOfDisplayRef.current.length > 0 && modesOfDisplayRef.current[0] === MODE.VOLUME_ESTIMATION) {
            if (twoPointsDistanceMeaseurementIcon) {
              drawIcon(ctx, twoPointsDistanceMeaseurementIcon.img, twoPointsDistanceMeaseurementIcon.x, twoPointsDistanceMeaseurementIcon.y, twoPointsDistanceMeaseurementIcon.w, twoPointsDistanceMeaseurementIcon.h)
            }
          }
        }

        if (showModify) {
          if (deleteImageIcon) {
            drawIcon(ctx, deleteImageIcon.img, deleteImageIcon.x, deleteImageIcon.y, deleteImageIcon.w, deleteImageIcon.h)
          }
        }

        if (showColorbar) {

          const ind = showOverlay ? 2 : applyColormap ? 0 : 1;
          if (ind < colorbarGradients.length) {

            if (!showOverlay) {
              const gradient = saving ? createGdraient(ctx, colorbars[ind].colormap, ctx.canvas.width * (1 - COLOR_BAR_WIDTH_PERCENT) / 2, ctx.canvas.width * COLOR_BAR_WIDTH_PERCENT)
                : colorbarGradients[ind]
              drawColorbarToImage(ctx, gradient, ctx.canvas.width * (1 - COLOR_BAR_WIDTH_PERCENT) / 2, ctx.canvas.height * COLOR_BAR_Y_PERCENT, ctx.canvas.width * COLOR_BAR_WIDTH_PERCENT, ctx.canvas.height * COLOR_BAR_HEIGHT_PERCENT)
            } else {
              let [modalityHighlightIconSize, modalityHighlightIconMargin] = getTopRightIconSizeAndMargin(canvas)
              const gradient = saving ? createGdraient(ctx, colorbars[ind].colormap, 0, ctx.canvas.width - modalityHighlightIconMargin - modalityHighlightIconSize * 2)//, ctx.canvas.width * COLOR_BAR_HEIGHT_PERCENT)
                : colorbarGradients[ind]
              drawColorbarToImage(ctx, gradient,
                ctx.canvas.width - modalityHighlightIconMargin - modalityHighlightIconSize / 2,
                modalityHighlightIconMargin + modalityHighlightIconSize * 3 / 2,
                ctx.canvas.width * COLOR_BAR_HEIGHT_PERCENT,
                ctx.canvas.height - modalityHighlightIconMargin - modalityHighlightIconSize * 2,
                FLOW_INTENSITY_LOWER_CASE, 1)
            }
          }
        }

        if (controlPanRef.current || mouseMiddlePanRef.current || pinchingRef.current || wheelingRef.current) {
          const topY = canvas.height / 2 - canvas.height * zoom.scale * (zoom.y)
          const bottomY = canvas.height / 2 + canvas.height * zoom.scale * (1 - zoom.y) + (canvas.height / cropPerc.y - canvas.height) * zoom.scale

          drawImageBorder(ctx, [topY, bottomY])
        }
        if (canvas.width > 0 && canvas.height > 0) {
          let modes = modesOfDisplayRef.current.filter((item) => (item !== MODE.THICKNESS_MEASUREMENT || item !== MODE.VOLUME_ESTIMATION));
          if (modesOfDisplayRef.current.includes(MODE.THICKNESS_MEASUREMENT)) {//In order to start with thickness measurement, to get measuerment width
            modes.unshift(MODE.THICKNESS_MEASUREMENT)
          }
          if (modesOfDisplayRef.current.includes(MODE.VOLUME_ESTIMATION)) {//In order to start with thickness measurement, to get measuerment width
            modes.unshift(MODE.VOLUME_ESTIMATION)
          }
          const measuermentEndPosition = {
            x: ctx.canvas.width * MEASUREMENT_POSITION_X_PERCENT,
            y: ctx.canvas.height * MEASUREMENT_POSITION_Y_PERCENT
          }
          const { height, fontHeight } = getMeasurementBoxHeight(ctx, MEASUREMENT_TEXT_PADDING, FONT_SIZE)
          let annotationEndPosition = { x: measuermentEndPosition.x, y: measuermentEndPosition.y }
          let segmentationLabelEndPosition = { x: ctx.canvas.width, y: measuermentEndPosition.y }
          const annotationStartPosition = {
            x: ctx.canvas.width * (1 - MEASUREMENT_POSITION_X_PERCENT) + MEASUREMENT_BOX_PADDING,
            y: ctx.canvas.height * MEASUREMENT_POSITION_Y_PERCENT
          }


          let xMult = 1, yMult = 1;
          if (croppingPercentRef.current.x !== cropPerc.x) {
            xMult = croppingPercentRef.current.x
          }
          if (croppingPercentRef.current.y !== cropPerc.y) {
            yMult = croppingPercentRef.current.y
          }
          if (configs[MODE.ANNOTATION]?.showPixelIntensity?.state && modes.includes(MODE.ANNOTATION)) {
            if (((storedLastImageRef.current === null && !saving) || forceRecalcImageStore) && targetSize.width && targetSize.height && annotationPointsRef.current.length > 0) {
              if (imageToDisplayRef.current) {
                if (imageToDisplayRef.current instanceof ImageBitmap) {
                  const canvas = document.createElement("canvas")
                  canvas.width = imageToDisplayRef.current.width
                  canvas.height = imageToDisplayRef.current.height
                  canvas.getContext("2d").drawImage(imageToDisplayRef.current, 0, 0)
                  storedLastImageRef.current = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height)
                } else {
                  storedLastImageRef.current = {
                    width: canvasImageRef.current.width / cropPerc.x,
                    height: canvasImageRef.current.height / cropPerc.y,
                    data: imageToDisplayRef.current,
                    oneChannel: gpuFormatRef.current === RedFormat,
                    float: gpuTypeRef.current === THREE.FloatType,
                  }
                }
              } else {
                const canvas = await getActImageAsCanvas(false, false, false, 1.0, true, false)
                storedLastImageRef.current = canvas.getContext("2d").getImageData(0, 0, canvas.width, canvas.height)

              }
            }
          }

          modes.forEach(mode => {
            switch (mode) {
              case MODE.VOLUME_ESTIMATION:
                if (configs[mode]?.enable) {

                  const tmp = volumeEstimationPointsRef.current.map(item => item.map(item2 => ({ ...item2, x: item2.x * xMult, y: item2.y * yMult })))
                  drawPoints(tmp, canvas, actZoom, mode)

                  if (showVolumeEstimation !== null) {
                    annotationEndPosition = displayVolumeEstimationValue(
                      ctx,
                      showVolumeEstimationRef.current,
                      measuermentEndPosition,
                      MEASUREMENT_TEXT_PADDING,
                      MEASUREMENT_BOX_PADDING,
                      FONT_SIZE)
                    segmentationLabelEndPosition = { ...annotationEndPosition }
                  }
                }
                break;
              case MODE.THICKNESS_MEASUREMENT:
                if (configs[mode]?.enable) {
                  const tmp = measurementPointsRef.current.map(item => item.map(item2 => ({ ...item2, x: item2.x * xMult, y: item2.y * yMult })))
                  drawPoints(tmp, canvas, actZoom, mode)
                  if (currentDistancesRef.current?.length > 0/* && currentDistances.length === measuermentColorsRef.current.colors.length*/ && configs[mode]?.showDistance) {//TODO DOMI
                    currentDistancesRef.current.forEach((item, index) => {
                      annotationEndPosition = drawMeasurements(ctx, index, item, measuermentEndPosition, MEASUREMENT_TEXT_PADDING, MEASUREMENT_BOX_PADDING, measuermentColorsRef.current.colors[index], index === currentDistancesRef.current.length - 1, FONT_SIZE)
                      segmentationLabelEndPosition = { ...annotationEndPosition }
                    })

                  }
                }

                break;
              case MODE.ANNOTATION:
                if (configs[mode]?.enable) {
                  let title = ""
                  const tmp = annotationPointsRef.current.map(item => item.map(item2 => ({ ...item2, x: item2.x * xMult, y: item2.y * yMult })))
                  drawPoints(tmp, canvas, actZoom, mode)
                  if (configs[mode]?.showPixelIntensity?.state && storedLastImageRef.current) {
                    let str = []
                    annotationPointsRef.current.forEach((item, index) => item.forEach(item2 => {
                      if (index < configs[mode].maxNum) {
                        const ch = storedLastImageRef.current.oneChannel === true ? 1 : 4
                        const x = (item2.x * cropPerc.x - 1 / storedLastImageRef.current.width / 2) * storedLastImageRef.current.width//In the gpu  0.5  hte middle of the px
                        const y = (item2.y * cropPerc.y - 1 / storedLastImageRef.current.height / 2) * storedLastImageRef.current.height
                        const ind1 = (Math.floor(y) * storedLastImageRef.current.width + Math.floor(x)) * ch
                        const ind2 = (Math.floor(y) * storedLastImageRef.current.width + Math.floor(x + 1)) * ch
                        const ind3 = (Math.floor(y + 1) * storedLastImageRef.current.width + Math.floor(x)) * ch
                        const ind4 = (Math.floor(y + 1) * storedLastImageRef.current.width + Math.floor(x + 1)) * ch

                        const r =
                          ((storedLastImageRef.current.data[ind4] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind3] * (Math.floor(x + 1) - x)) * (y - Math.floor(y))
                            + (storedLastImageRef.current.data[ind2] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind1] * (Math.floor(x + 1) - x)) * (Math.floor(y + 1) - y)) || 0
                        let g;
                        switch (configs[mode]?.showPixelIntensity?.type) {
                          case PIXEL_INTESITY_TYPE.RGB:
                            title = RGB
                            g =
                              ((storedLastImageRef.current.data[ind4 + 1] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind3 + 1] * (Math.floor(x + 1) - x)) * (y - Math.floor(y))
                                + (storedLastImageRef.current.data[ind2 + 1] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind1 + 1] * (Math.floor(x + 1) - x)) * (Math.floor(y + 1) - y)) || 0
                            const b =
                              ((storedLastImageRef.current.data[ind4 + 2] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind3 + 2] * (Math.floor(x + 1) - x)) * (y - Math.floor(y))
                                + (storedLastImageRef.current.data[ind2 + 2] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind1 + 2] * (Math.floor(x + 1) - x)) * (Math.floor(y + 1) - y)) || 0

                            if (storedLastImageRef.current.float === true) {
                              str.push(`${item2.label}) ${Math.round(r * 255)}, ${Math.round(g * 255)}, ${Math.round(b * 255)}`)
                            } else {
                              str.push(`${item2.label}) ${Math.round(r)}, ${Math.round(g)}, ${Math.round(b)}`)
                            }


                            break;
                          case PIXEL_INTESITY_TYPE.INTENSITY_R:
                            title = INTENSITY
                            str.push(`${item2.label}. ${Math.round(r / (storedLastImageRef.current.float === true ? 1 : 255) * 100)}%`)
                            break;
                          case PIXEL_INTESITY_TYPE.INTENSITY_RG:
                            title = INTENSITY
                            g =
                              ((storedLastImageRef.current.data[ind4 + 1] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind3 + 1] * (Math.floor(x + 1) - x)) * (y - Math.floor(y))
                                + (storedLastImageRef.current.data[ind2 + 1] * (x - Math.floor(x)) + storedLastImageRef.current.data[ind1 + 1] * (Math.floor(x + 1) - x)) * (Math.floor(y + 1) - y)) || 0
                            str.push(`${item2.label}. ${Math.round((r + (g << 8)) / (storedLastImageRef.current.float ? 1 : (255 * 256)) * 100)}%`)
                            break;
                          default:
                            break;
                        }


                      }
                    }))


                    if (str?.length > 0) {
                      str.sort()
                      drawIntensityValues(ctx, `${title} ${str.join("  ")}`,
                        ctx.canvas.width * MEASUREMENT_POSITION_X_PERCENT - MEASUREMENT_BOX_PADDING, ctx.canvas.height * MEASUREMENT_POSITION_Y_PERCENT, INTESNITY_FONT_SIZE, MEASUREMENT_TEXT_PADDING)
                    }
                  }
                }
                break;
              case MODE.FREE_TEXT:
                if (configs[mode]?.enable) {
                  drawFreeText(ctx,
                    freeTextRef.current.text || "",
                    annotationStartPosition.x, measuermentEndPosition.y - height,
                    annotationEndPosition.x - annotationStartPosition.x - MEASUREMENT_BOX_PADDING, height, fontHeight,
                    MEASUREMENT_TEXT_PADDING
                  )

                }
                break;
              default:
                break;
            }
          })
          if (canvas === canvasExtraDrawRef.current) {
            setMeasuermentDrawStartOnDisplay({ x: (segmentationLabelEndPosition.x - MEASUREMENT_BOX_PADDING) / ctx.canvas.width, y: (measuermentEndPosition.y - height) / ctx.canvas.height, height: height / ctx.canvas.height })
          }
          if (saving && showOverlayRef.current && overlayType === OVERLAY_TYPE.SEGMENTATION) {
            if (activeLayerOfOverlayRef.current) {
              drawSegmentationLabelWithActiveLayerToImage(ctx, MEASUREMENT_BOX_PADDING, measuermentEndPosition.y - height, segmentationLabelEndPosition.x - 2 * MEASUREMENT_BOX_PADDING, height, activeLayerOfOverlayRef.current, overlayExtraDataRef.current[SEGMENTATION_LAYER_ENUM[activeLayerOfOverlayRef.current]])
            } else {
              drawSegmentationLabelToImage(ctx, MEASUREMENT_BOX_PADDING, measuermentEndPosition.y - height, segmentationLabelEndPosition.x - 2 * MEASUREMENT_BOX_PADDING, height, calcSegmentationLabelMetrics(),)
            }
          }
        }

      }
    }// eslint-disable-next-line
  }, [
    showModify,
    measuermentColors,
    volumeEstimationColors,
    controlPan,
    mouseMiddlePan,
    moveHighlightedPoint,
    pinching,
    wheeling,
    annotationPoints,
    currentDistances,
    currentVEDistances,
    deleteIcon,
    deleteImageIcon,
    zoom,
    freeText,
    measurementPoints,
    volumeEstimationPoints,
    modesOfDisplay,
    showColorbar,
    configs,
    colorbarGradients,
    applyColormap,
    croppingPercent,
    sizeInPx,
    sizeMM,
    drawPoints,
    extraDrawOnDimLayer,
    showOverlay, activeLayerOfOverlay,
    showVolumeEstimation,
    overlayType
  ]);

  //Reset tools
  const reset = useCallback(() => {
    setAnnotationPoints([])
    setMeasurementPoints([])
    setVolumeEstimationPoints([])
    setMeasuermentColors({ colors: [], options: [...(configsRef.current[MODE.THICKNESS_MEASUREMENT]?.colors ?? [])] })
    setVolumeEstimationColors({ colors: [], options: [...(configsRef.current[MODE.VOLUME_ESTIMATION]?.colors ?? [])] })
    setPoints([])
    setFreeText({})
    // eslint-disable-next-line
  }, [])

  //Draw image
  const drawImage = useCallback(async (img = imageToDisplay, cropPerc = croppingPercent, actZoom = zoom, withDimension = true, drawDimensionsToo = true, toSave = false, forceDeafult = false) => {
    try {
      let nativeValue = null
      if ((img || toSave) && threeRef.current) {
        const { x, y, newW, newH } = getGPUZoomParams(cropPerc, actZoom)
        nativeValue = updateUnifrom(threeRef.current,
          {
            forceDeafult,
            applyColormap: forceDeafult ? false : applyColormapRef.current && !showOverlayRef.current,
            background: showOverlayRef.current && overlayTypeRef.current === OVERLAY_TYPE.LINE_DOPPLER,
            origin: new THREE.Vector2((x + 1) / 2, (y + 1) / 2), size: new THREE.Vector2(newW / 2, newH / 2), ...extraGPUParamsRef.current
          }
        )

        if (img) {
          if (threeRef.current.material instanceof ShaderMaterial) {
            if (threeRef.current.material.uniforms.tDiffuse.value instanceof DataTexture &&
              gpuFormatRef.current === threeRef.current.material.uniforms.tDiffuse.value.format &&
              gpuTypeRef.current === threeRef.current.material.uniforms.tDiffuse.value.type) {
              threeRef.current.material.uniforms.tDiffuse.value.image.data = img
            } else {
              const dataTexture = new THREE.DataTexture(
                img,
                canvasImageRef.current.width, canvasImageRef.current.height,
                gpuFormatRef.current,
                gpuTypeRef.current,
                THREE.UVMapping,
                THREE.ClampToEdgeWrapping,
                THREE.ClampToEdgeWrapping,
                THREE.LinearFilter,
                THREE.LinearFilter
              );
              threeRef.current.material.uniforms.tDiffuse.value = dataTexture;
            }
            threeRef.current.material.uniforms.tDiffuse.value.needsUpdate = true;
          } else {
            threeRef.current.material.map.image.data = img;
            threeRef.current.material.map.needsUpdate = true;
          }
        }


      }
      if (threeRef.current) {
        threeRef.current.render()
        storedLastImageRef.current = null
        if (!firstRenderRef.current) {
          setFirstRender(true)
        }
      }
      if (drawDimensionsToo) {
        drawDimensions(canvasDimensionRef.current, actZoom, cropPerc, true, withDimension, true);
      }
      if (nativeValue) {
        updateUnifrom(threeRef.current,
          {
            ...nativeValue
          }
        )
      }

    } catch (e) {
      console.error(e)
    }// eslint-disable-next-line
  }, [imageToDisplay]);

  //only dispaly oaram change not the image
  const updateImage = useCallback(async (actZoom = zoom, withDimension = true, drawDimensionsToo = true) => {
    try {
      if (threeRef.current) {

        const { x, y, newW, newH } = getGPUZoomParams(croppingPercent, actZoom)
        updateUnifrom(threeRef.current, {
          applyColormap: applyColormapRef.current && !showOverlayRef.current,
          background: showOverlayRef.current && overlayTypeRef.current === OVERLAY_TYPE.LINE_DOPPLER,
          origin: new THREE.Vector2((x + 1) / 2, (y + 1) / 2), size: new THREE.Vector2(newW / 2, newH / 2), ...extraGPUParams
        })
        threeRef.current.render()
        //storedLastImageRef.current = null
      }
      if (drawDimensionsToo) {
        drawDimensions(canvasDimensionRef.current, actZoom, croppingPercent, true, withDimension, true);
      }

    } catch (e) {
      console.error(e)
    }// eslint-disable-next-line
  }, [showOverlay, applyColormap,
    croppingPercent,
    zoom,
    drawDimensions,//TODO:modesOfdisplay kelle? mergenél kivettem
    extraGPUParams,
  ]);

  // eslint-disable-next-line
  useEffect(drawDimensions, [targetSize])


  useEffect(drawExtra, [drawExtra])
  useEffect(updateImage, [updateImage])
  // eslint-disable-next-line
  useEffect(() => { setRulerColoring(dynamicRulerColoring) }, [dynamicRulerColoring])
  useEffect(() => {
    const config = configs[MODE.THICKNESS_MEASUREMENT]
    if (config?.fixDistance) {
      setMeasurementPoints(s => {
        const newS = [...s]
        newS.forEach((m, ind) => {
          const newAbsP = getFixDistancePoint(newS[ind][0], newS[ind][1], config.fixDistance, zoomRef.current, canvasExtraDrawRef.current)
          const newRelP = {
            x: zoomRef.current.x + (-DEFAULT_ZOOM.x + newAbsP.x / canvasExtraDrawRef.current.width) / zoomRef.current.scale,
            y: zoomRef.current.y + (-DEFAULT_ZOOM.y + newAbsP.y / canvasExtraDrawRef.current.height) / zoomRef.current.scale,
          }
          newS[ind][1] = { ...newS[ind][1], x: newRelP.x, y: newRelP.y }
        })
        return newS
      })
    }
    // eslint-disable-next-line
  }, [configs])

  //Draw overlay
  const drawOverlay = useCallback((img = overlayToDisplay, actZoom = zoom, cropPer = croppingPercent) => {
    try {//TODO: when redraw?
      if (img) {

        if (!threeRefOverlay.current) {
          //const newWidth = 120//img.width * cropPer.x
          const newWidth = img.useUSWidth ? canvasImageRef.current.width : img.width
          const newHeight = img.height
          const data = new Float32Array(newWidth * newWidth);
          const dataTexture = new THREE.DataTexture(
            data,
            img.width, img.height,
            RedFormat,
            THREE.FloatType,
            THREE.UVMapping,
            THREE.ClampToEdgeWrapping,
            THREE.ClampToEdgeWrapping,
            img.filter ?? THREE.NearestFilter,
            img.filter ?? THREE.NearestFilter
          );
          dataTexture.needsUpdate = true;
          overlayShader.uniforms.tDiffuse.value = dataTexture

          const { renderer, scene, camera, rendererTarget, material, geometry } = create2DSimpleImageScene(canvasOverlayRef?.current, { width: newWidth, height: newHeight }, overlayShader)

          threeRefOverlay.current = { renderer, scene, camera, material, rendererTarget, geometry };
          threeRefOverlay.current.render = () => { threeRefOverlay.current.renderer.render(threeRefOverlay.current.scene, threeRefOverlay.current.camera); };

        }
        const { x, y, newW, newH, } = getGPUZoomParams(cropPer, actZoom)
        updateOverlayUnifrom(threeRefOverlay.current, { ...extraGPUParams, mode: overlayTypeRef.current, activeLayerOfOverlay: activeLayerOfOverlayRef.current, scale: actZoom.scale, origin: new THREE.Vector2((x + 1) / 2, (y + 1) / 2), size: new THREE.Vector2(newW / 2, newH / 2) })


        if (threeRefOverlay.current.isComposer) {
          threeRefOverlay.current.material.map.image.data = img.data;
          threeRefOverlay.current.material.map.needsUpdate = true;
        } else {
          threeRefOverlay.current.material.uniforms.tDiffuse.value.image.data = img.data
          threeRefOverlay.current.material.uniforms.tDiffuse.value.needsUpdate = true;
        }
        threeRefOverlay.current.render();
      } else {
        if (threeRefOverlay.current) {
          const { x, y, newW, newH, } = getGPUZoomParams(cropPer, actZoom)
          updateOverlayUnifrom(threeRefOverlay.current, { ...extraGPUParams, mode: overlayTypeRef.current, activeLayerOfOverlay: activeLayerOfOverlayRef.current, scale: actZoom.scale, origin: new THREE.Vector2((x + 1) / 2, (y + 1) / 2), size: new THREE.Vector2(newW / 2, newH / 2) })

          threeRefOverlay.current.render()



          /*
          const rt = threeRefOverlay.current.composer.writeBuffer
          const buffer = new Float32Array(4 * rt.width * rt.height);

          threeRefOverlay.current.renderer.readRenderTargetPixels(rt, 0, 0, rt.width, rt.height, buffer);
          */
          // console.log("buffer")
          //console.log(buffer.filter((it, ind) => ind % 4 === 0))

        }
      }

    } catch (e) {
      console.error(e)
    }// eslint-disable-next-line
  }, [overlayToDisplay]);



  const updateOverlay = useCallback((actZoom = zoom, cropPer = croppingPercent) => {
    try {//TODO: when redraw?
      if (threeRefOverlay.current && showOverlay) {
        const { x, y, newW, newH, } = getGPUZoomParams(cropPer, actZoom)
        updateOverlayUnifrom(threeRefOverlay.current, { ...extraGPUParams, mode: overlayType, activeLayerOfOverlay: activeLayerOfOverlayRef.current, scale: actZoom.scale, origin: new THREE.Vector2((x + 1) / 2, (y + 1) / 2), size: new THREE.Vector2(newW / 2, newH / 2) })
        threeRefOverlay.current.render();
      }

    } catch (e) {
      console.error(e)
    }// eslint-disable-next-line
  }, [zoom, croppingPercent, showOverlay, activeLayerOfOverlay, overlayType, extraGPUParams]);

  /**
   * Calculate new zoom object
   * @param {Object} prevZoom prev zoom
   * @param {Number} newScale new scale
   * @param {Number} x x position of click
   * @param {Number} y y position of click
   * @returns new zoom
   */
  const calculateNewZoom = useCallback((prevZoom, newScale, x, y) => {
    // Width and hight  of image
    const width = canvasImageRef.current.width,
      height = canvasImageRef.current.height;
    //Mouse position in image coordinate
    const cX = width * prevZoom.x;
    const cY = height * prevZoom.y;
    //Size of prev image part that is display in image coordinate
    const scaledWidth = width / prevZoom.scale;
    const scaledHeight = height / prevZoom.scale;
    //Size of new image part that is display in image coordinate
    const scaledWidthN = width / newScale;
    const scaledHeightN = height / newScale;

    const newY = (cY -
      scaledHeight / 2 +
      y * scaledHeight -
      y * scaledHeightN +
      scaledHeightN / 2) /
      height

    const newX = (cX -
      scaledWidth / 2 +
      x * scaledWidth -
      x * scaledWidthN +
      scaledWidthN / 2) /
      width
    return {
      ...prevZoom,
      x: zoomOption?.horizontalPanOut?.state ? getMaxMinXPan(newX, newScale, width, zoomOption?.horizontalPanOut?.maxXLeft, zoomOption?.horizontalPanOut?.maxXRight, croppingPercent) : Math.min(Math.max(newX, scaledWidthN / 2 / width), (width - scaledWidthN / 2) / width),
      y: zoomOption?.verticalPanOut?.state ? getMaxMinYPan(newY, newScale, height, zoomOption?.verticalPanOut?.maxYTop, zoomOption?.verticalPanOut?.maxYBottom, croppingPercent) : Math.min(
        Math.max(newY, scaledHeightN / 2 / height
        ),
        (height - scaledHeightN / 2) / height
      ),
      scale: newScale,
    };
  }, [zoomOption, croppingPercent])
  //Delete point tool if in given area
  const deleteIfInDeleteArea = useCallback((pointSets, pointSetSetter, colors) => {
    const x = zoomRef.current.x - 0.5 / zoomRef.current.scale
    const y = zoomRef.current.y - 0.5 / zoomRef.current.scale
    const size = 1 / zoomRef.current.scale
    const relPos = {
      x: (pointSets[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2].x - x) / size,
      y: (pointSets[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2].y - y) / size
    }

    if (relPos.x > deleteIconRef.current.x && relPos.y > deleteIconRef.current.y && relPos.x < deleteIconRef.current.x + deleteIconRef.current.w && relPos.y < deleteIconRef.current.y + deleteIconRef.current.h) {//in delete area
      pointSetSetter(s => {
        const newS = [...s];
        newS.splice(highlightedPointRef.current.ind1, 1)
        return newS;
      })
      if (colors?.colors) {
        colors.options.push(colors.colors[highlightedPointRef.current.ind1])
        colors.colors.splice(highlightedPointRef.current.ind1, 1)
      }

      setMoveHighlightedPoint(false);
      setHighlightedPoint(null);

    }
  }// eslint-disable-next-line
    , [])

  //Calculate distances
  const calculateDistances = useCallback((arrOfpoints, setDistance = null) => {
    if (sizeMMRef.current?.width && sizeMMRef.current?.height) {
      const d = arrOfpoints.map(item => {
        const p1 = item[0];
        const p2 = item[1];
        return {
          dy: Math.round(Math.abs(p2.y - p1.y) * sizeMMRef.current.height * 100) / 100,
          dx: Math.round(Math.abs(p2.x - p1.x) * sizeMMRef.current.width * 100) / 100,
          d: Math.round(Math.pow(Math.pow(Math.abs(p2.y - p1.y) * sizeMMRef.current.height, 2) + Math.pow(Math.abs(p2.x - p1.x) * sizeMMRef.current.width, 2), 0.5) * 100) / 100
        }
      })
      if (setDistance) {
        setDistance(d)
      }
      return d;
    }
    // eslint-disable-next-line
  }, [sizeMM, actMode])

  //Handle mouse wheel
  const handleWheel = useCallback((e) => {
    if (zoomOption?.enable) {
      if (wheelingTiemrIdRef.current !== null) {
        clearTimeout(wheelingTiemrIdRef.current)
      } else {
        setWheeling(true)
      }
      wheelingTiemrIdRef.current = setTimeout(() => { setWheeling(false); wheelingTiemrIdRef.current = null }, 250)

      const currentTargetRect = canvasImageRef.current.getBoundingClientRect();
      const x =
        (e.clientX - currentTargetRect.left) /
        canvasImageRef.current.clientWidth,
        y =
          (e.clientY - currentTargetRect.top) /
          canvasImageRef.current.clientHeight;

      setZoom((z) => {
        //New Zoom
        let newScale;
        if (e.deltaY > 0) {
          newScale =
            z.scale / ZOOM_SCALE_FACTOR < 1.0
              ? 1.0
              : z.scale / ZOOM_SCALE_FACTOR;
        } else if (e.deltaY < 0) {
          newScale =
            z.scale * ZOOM_SCALE_FACTOR > zoomOption?.maxZoom
              ? zoomOption?.maxZoom
              : z.scale * ZOOM_SCALE_FACTOR;
        } else {
          return z;
        }
        const newZoom = calculateNewZoom(z, newScale, x, y);
        if (newZoom.x === z.x && newZoom.y === z.y && newZoom.scale === z.scale) {
          return z
        }
        return newZoom;
      });
    }// eslint-disable-next-line 
  }, [calculateNewZoom, zoomOption])
  /**
   * handle edit edit a point
   * @param {{x,y}} relPos act pos
   */
  const handleEditPoint = useCallback((inPointsSet, relPos, pointSetSetter, offset = false) => {
    const r = offset ? (ANNOTATION_POINT_CONFIG.length + ANNOTATION_POINT_CONFIG.radius) / Math.sqrt(2) : 0
    const rx = r / divEventRef.current.width / zoomRef.current.scale
    const ry = r / divEventRef.current.height / zoomRef.current.scale
    if (moveHighlightedPointRef.current) {
      pointSetSetter(s => {
        const newS = [...s];
        newS[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2] = {
          ...newS[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2],
          x: relPos.x - rx - highlightedPointRef.current.d1, y: relPos.y + ry - highlightedPointRef.current.d2
        };
        return newS;
      })
    } else {
      let ind1 = null;
      let ind2 = null;
      let d1 = 0;
      let d2 = 0;
      let minD = Infinity;
      const th = EDITABLE_RADIUS / zoomRef.current.scale
      inPointsSet.forEach((inPoints, index1) => {
        inPoints.forEach((item, index2) => {
          const d = Math.hypot(relPos.x - (item.x + rx), relPos.y - (item.y - ry));
          if (d < th) {
            if (d < minD) {
              ind1 = index1;
              ind2 = index2;
              d1 = relPos.x - (item.x + rx);
              d2 = relPos.y - (item.y - ry);
              minD = d;
            }
          }
        })
      })
      if (ind1 !== null && ind2 !== null) {
        setHighlightedPoint({ ind1, ind2, d1, d2 });
      } else {
        setHighlightedPoint(null);
      }


    }// eslint-disable-next-line
  }, [])


  /**
   * Is it modality highlight area
   */
  const isItModHighArea = useCallback((event, isCanvas = true) => {

    let currentTargetRect =
      canvasImageRef.current?.getBoundingClientRect();
    const x = isCanvas ? (event.clientX - currentTargetRect.left) : (event.clientX),
      y = isCanvas ? (event.clientY - currentTargetRect.top) : (event.clientY);


    let [modalityHighlightIconSize, modalityHighlightIconMargin] = getTopRightIconSizeAndMargin(canvasExtraDrawRef.current)

    const x_center = (canvasExtraDrawRef.current.width - modalityHighlightIconSize / 2 - modalityHighlightIconMargin) /
      (canvasExtraDrawRef.current.width),
      y_center = (modalityHighlightIconMargin + modalityHighlightIconSize / 2) / (canvasExtraDrawRef.current.height)

    const xPos = 2 * x / canvasExtraDrawRef.current.width,
      yPos = 2 * y / canvasExtraDrawRef.current.height;

    return Math.hypot(xPos - x_center, yPos - y_center) <= (modalityHighlightIconSize / canvasExtraDrawRef.current.height) / 2
  }, []);


  /**
  * Is it delete image area
  */
  const isItDeleteImageArea = useCallback((event, isCanvas = true) => {

    let currentTargetRect =
      canvasImageRef.current?.getBoundingClientRect();
    const x = isCanvas ? (event.clientX - currentTargetRect.left) : (event.clientX),
      y = isCanvas ? (event.clientY - currentTargetRect.top) : (event.clientY);

    const x_center = 1 / 2,
      y_center = 1 - ((DELETE_ICON_SIZE / 2) / canvasExtraDrawRef.current.height)

    const xPos = 2 * x / canvasExtraDrawRef.current.width,
      yPos = 2 * y / canvasExtraDrawRef.current.height;

    return Math.hypot(xPos - x_center, yPos - y_center) <= (DELETE_ICON_SIZE / canvasExtraDrawRef.current.height) / 2
  }, []);


  /**
   * On Mouse move handle for ultrasound image - thickness measurement
   */
  const onMouseMoveHandle = useCallback(
    (event) => {
      if (mouseMiddlePanRef.current) {
        setZoom((z) => {
          const width = canvasImageRef.current.width,
            height = canvasImageRef.current.height;
          const scaledWidth = width / z.scale;
          const scaledHeight = height / z.scale;
          const newX = z.x - event.movementX / divEventRef.current.clientWidth / z.scale;
          const newY = z.y - event.movementY / divEventRef.current.clientHeight / z.scale;
          return {
            ...z,
            x: zoomOption?.horizontalPanOut?.state ? getMaxMinXPan(newX, z.scale, width, zoomOption?.horizontalPanOut?.maxXLeft, zoomOption?.horizontalPanOut?.maxXRight, croppingPercent) : Math.min(Math.max(newX, scaledWidth / 2 / width), (width - scaledWidth / 2) / width),
            y: zoomOption?.verticalPanOut?.state ? getMaxMinYPan(newY, z.scale, height, zoomOption?.verticalPanOut?.maxYTop, zoomOption?.verticalPanOut?.maxYBottom, croppingPercent) : Math.min(Math.max(newY, scaledHeight / 2 / height), (height - scaledHeight / 2) / height)
          };
        });
      } else {
        let currentTargetRect =
          canvasImageRef.current?.getBoundingClientRect();
        const x = event.clientX - currentTargetRect.left,
          y = event.clientY - currentTargetRect.top;

        const relPos = absoluteToRelativePointPosition({ x, y }, zoomRef.current, canvasImageRef.current);

        const activeMeasurementOrVolumeEstimationFilter = configs[MODE.VOLUME_ESTIMATION]?.drawIcon && (modesOfDisplayRef?.current !== null && modesOfDisplayRef.current.length > 0 && (modesOfDisplayRef.current[0] === MODE.THICKNESS_MEASUREMENT || modesOfDisplayRef.current[0] === MODE.VOLUME_ESTIMATION))
        if (/*expandable && !moveHighlightedPoint*/((expandable && !activeMeasurementOrVolumeEstimationFilter) ||
          (activeMeasurementOrVolumeEstimationFilter && !(showOverlay && overlayTypeRef.current === OVERLAY_TYPE.LINE_DOPPLER)))) {

          if (isItModHighArea(event)) {
            document.body.style.cursor = 'grab';
          } else {
            document.body.style.cursor = '';
          }
        }

        if (showModify) {
          if (isItDeleteImageArea(event)) {
            document.body.style.cursor = 'grab';
          } else {
            document.body.style.cursor = '';
          }
        }

        switch (actMode) {
          case MODE.VOLUME_ESTIMATION:
            if (configsRef.current[actMode]?.enable) {
              if (points.length === 1) {
                setCurrentMousePosition(relPos);
                const pointToCalc = [...volumeEstimationPoints, [relPos, points[0]]]
                calculateDistances(pointToCalc, setCurrentVEDistances)
              }
              else if (configsRef.current[actMode]?.editable) {

                const fd = configsRef.current[actMode]?.fixDistance || null
                if (fd === null) {
                  handleEditPoint(volumeEstimationPoints, relPos, setVolumeEstimationPoints, false)
                } else {
                  if (points.length > 0 || volumeEstimationPoints.length > 0) {
                    if (moveHighlightedPointRef.current) {
                      const dx = volumeEstimationPointsRef.current[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2].x - relPos.x + highlightedPointRef.current.d1
                      const dy = volumeEstimationPointsRef.current[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2].y - relPos.y + highlightedPointRef.current.d2

                      setVolumeEstimationPoints(s => {
                        const newS = [...s];
                        for (let i = 0; i < newS[highlightedPointRef.current.ind1].length; i++) {
                          newS[highlightedPointRef.current.ind1][i] = {
                            ...newS[highlightedPointRef.current.ind1][i],
                            x: newS[highlightedPointRef.current.ind1][i].x - dx,
                            y: newS[highlightedPointRef.current.ind1][i].y - dy
                          };
                        }

                        return newS;
                      })
                    } else {
                      handleEditPoint(volumeEstimationPoints, relPos, setVolumeEstimationPoints, false)
                    }
                  }

                }
              }

            }
            break;
          case MODE.THICKNESS_MEASUREMENT:
            if (configsRef.current[actMode]?.enable) {
              if (points.length === 1) {
                setCurrentMousePosition(relPos);
                const pointToCalc = [...measurementPoints, [relPos, points[0]]]
                calculateDistances(pointToCalc, setCurrentDistances)
              }
              else if (configsRef.current[actMode]?.editable) {



                const fd = configsRef.current[actMode]?.fixDistance || null
                if (fd === null) {
                  handleEditPoint(measurementPoints, relPos, setMeasurementPoints, false)
                } else {
                  if (points.length > 0 || measurementPoints.length > 0) {
                    if (moveHighlightedPointRef.current) {
                      const dx = measurementPointsRef.current[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2].x - relPos.x + highlightedPointRef.current.d1
                      const dy = measurementPointsRef.current[highlightedPointRef.current.ind1][highlightedPointRef.current.ind2].y - relPos.y + highlightedPointRef.current.d2

                      setMeasurementPoints(s => {
                        const newS = [...s];
                        for (let i = 0; i < newS[highlightedPointRef.current.ind1].length; i++) {
                          newS[highlightedPointRef.current.ind1][i] = {
                            ...newS[highlightedPointRef.current.ind1][i],
                            x: newS[highlightedPointRef.current.ind1][i].x - dx,
                            y: newS[highlightedPointRef.current.ind1][i].y - dy
                          };
                        }

                        return newS;
                      })
                    } else {
                      handleEditPoint(measurementPoints, relPos, setMeasurementPoints, false)
                    }
                  }

                }
              }

            }
            break;
          case MODE.ANNOTATION:
            if (configsRef.current[actMode]?.enable) {
              if (points.length === 1) {
                setCurrentMousePosition(relPos);
              }
              if (configsRef.current[actMode]?.editable) {
                handleEditPoint(annotationPoints, relPos, setAnnotationPoints, configsRef.current[actMode]?.label === ANNOTAION_LABEL_TYPE.MAGNIFIER ? true : false)
              }
            }
            break;
          default:
            break;
        }
      }



    },// eslint-disable-next-line
    [actMode, points, annotationPoints, measurementPoints, volumeEstimationPoints, handleEditPoint, calculateDistances, zoomOption, croppingPercent, showModify]
  );
  /**
   * Mouse down handle us
   * @param {Event} event mouse click event
   */
  const onMouseDownHandle = useCallback((e) => {
    if (e.button === 0) {//left click
      setMouseDownPosition({ x: e.clientX, y: e.clientY });
      if (highlightedPointRef.current !== null) {
        setMoveHighlightedPoint(true)
      }
    } else if (e.button === 1) {//middle click
      setZoom((z) => ({ ...z, startPos: { x: z.x, y: z.y } }));
      setMouseMiddlePan(true)
    }
    // eslint-disable-next-line
  }, [])

  /**
   * Mouse up handle us
   * @param {Event} event mouse click event
   */
  const onMouseUpHandle = useCallback(
    (e) => {
      onImageClick(e, isItModHighArea(e));
      if (mouseMiddlePanRef.current) {
        setMouseMiddlePan(false)
      } else {
        if (moveHighlightedPointRef.current) {
          switch (actMode) {
            case MODE.VOLUME_ESTIMATION:
              deleteIfInDeleteArea(volumeEstimationPointsRef.current, setVolumeEstimationPoints, volumeEstimationColorsRef.current)
              break;
            case MODE.THICKNESS_MEASUREMENT:
              deleteIfInDeleteArea(measurementPointsRef.current, setMeasurementPoints, measuermentColorsRef.current)
              break;
            case MODE.ANNOTATION:
              deleteIfInDeleteArea(annotationPointsRef.current, setAnnotationPoints, null)
              break;
            default:
              break
          }
          setMoveHighlightedPoint(false);
          return;
        }
        if (expandable && !moveHighlightedPoint && notMeasureOrVolumeEstimation()) {
          if (isItModHighArea(e)) {
            if (!modalityHighlight) {
              onImageExpandClick(targetSize);
            }
            setModalityHighlight(s => !s)
            return;
          }
        }

        if (configs[MODE.VOLUME_ESTIMATION]?.drawIcon && !moveHighlightedPointRef.current && isItModHighArea(e) && !(showOverlay && overlayTypeRef.current === OVERLAY_TYPE.LINE_DOPPLER)) {
          if (modesOfDisplayRef?.current !== null && modesOfDisplayRef.current.length > 0 && modesOfDisplayRef.current[0] === MODE.THICKNESS_MEASUREMENT) {
            if (pointsRef.current.length > 0) {
              setPoints([])
              measuermentColorsRef.current.options.push(measuermentColorsRef.current.colors.slice(-1)[0])
              setMeasuermentColors({ colors: measuermentColorsRef.current.colors.slice(0, -1), options: measuermentColorsRef.current.options })
            }
            handleTMVEswitch(MODE.THICKNESS_MEASUREMENT)
          } else if (modesOfDisplayRef?.current !== null && modesOfDisplayRef.current.length > 0 && modesOfDisplayRef.current[0] === MODE.VOLUME_ESTIMATION) {
            if (pointsRef.current.length > 0) {
              setPoints([])
              volumeEstimationColorsRef.current.options.push(volumeEstimationColorsRef.current.colors.slice(-1)[0])
              setVolumeEstimationColors({ colors: volumeEstimationColorsRef.current.colors.slice(0, -1), options: volumeEstimationColorsRef.current.options })
            }
            handleTMVEswitch(MODE.VOLUME_ESTIMATION)
          }
        } else {
          let currentTargetRect =
            canvasImageRef.current.getBoundingClientRect();
          const x = e.clientX - currentTargetRect.left,
            y = e.clientY - currentTargetRect.top;

          const relPos = absoluteToRelativePointPosition({ x, y }, zoomRef.current, canvasImageRef.current);

          switch (actMode) {
            case MODE.VOLUME_ESTIMATION:
              if (configsRef.current[actMode]?.enable) {
                if (volumeEstimationPointsRef.current.length >= configsRef.current[actMode]?.maxNum) {
                  setMessage(MESSAGES.REACH_MAX_NUM_LIMIT);
                  return;
                }
                if (/*Math.abs(mouseDownPosition.x - e.clientX) + Math.abs(mouseDownPosition.y - e.clientY) === 0*/true && !e.ctrlKey) {
                  if (addMeasurementPointCondition(relPos, points.length)) {
                    if (points.length > 0) {
                      const fd = configsRef.current[actMode]?.fixDistance || null
                      if (fd === null) {
                        setVolumeEstimationPoints(m => [...m, [...points, relPos]]);
                      } else {
                        const newAbsP = getFixDistancePoint(points[0], currentMousePositionRef.current, fd, zoomRef.current, canvasExtraDrawRef.current)
                        const newRelP = {
                          x: zoomRef.current.x + (-DEFAULT_ZOOM.x + newAbsP.x / canvasExtraDrawRef.current.width) / zoomRef.current.scale,
                          y: zoomRef.current.y + (-DEFAULT_ZOOM.y + newAbsP.y / canvasExtraDrawRef.current.height) / zoomRef.current.scale,
                        }
                        setVolumeEstimationPoints(m => [...m, [...points, newRelP]]);
                      }

                    } else {
                      volumeEstimationColorsRef.current.colors.push(volumeEstimationColorsRef.current.options.pop())
                    }
                    setPoints(p => {
                      if (p.length === 0) {
                        return [relPos];
                      } else {
                        return [];
                      }
                    })
                  }
                }
              }
              break;
            case MODE.THICKNESS_MEASUREMENT:
              if (configsRef.current[actMode]?.enable) {
                if (measurementPointsRef.current.length >= configsRef.current[actMode]?.maxNum) {
                  setMessage(MESSAGES.REACH_MAX_NUM_LIMIT);
                  return;
                }
                if (/*Math.abs(mouseDownPosition.x - e.clientX) + Math.abs(mouseDownPosition.y - e.clientY) === 0*/true && !e.ctrlKey) {
                  if (addMeasurementPointCondition(relPos, points.length)) {
                    if (points.length > 0) {
                      const fd = configsRef.current[actMode]?.fixDistance || null
                      if (fd === null) {
                        setMeasurementPoints(m => [...m, [...points, relPos]]);
                      } else {
                        const newAbsP = getFixDistancePoint(points[0], currentMousePositionRef.current, fd, zoomRef.current, canvasExtraDrawRef.current)
                        const newRelP = {
                          x: zoomRef.current.x + (-DEFAULT_ZOOM.x + newAbsP.x / canvasExtraDrawRef.current.width) / zoomRef.current.scale,
                          y: zoomRef.current.y + (-DEFAULT_ZOOM.y + newAbsP.y / canvasExtraDrawRef.current.height) / zoomRef.current.scale,
                        }
                        setMeasurementPoints(m => [...m, [...points, newRelP]]);

                      }

                    } else {
                      measuermentColorsRef.current.colors.push(measuermentColorsRef.current.options.pop())
                    }
                    setPoints(p => {
                      if (p.length === 0) {
                        return [relPos];
                      } else {
                        return [];
                      }
                    })
                  }
                }
              }
              break;
            case MODE.ANNOTATION:

              if (configsRef.current[actMode]?.enable) {
                if (annotationPointsRef.current.length >= configsRef.current[actMode]?.maxNum && !configsRef.current[actMode]?.overWriteLast) {
                  setMessage(MESSAGES.REACH_MAX_NUM_LIMIT);
                  return;
                }
                if (/*Math.abs(mouseDownPosition.x - e.clientX) + Math.abs(mouseDownPosition.y - e.clientY) === 0*/true && !e.ctrlKey) {
                  if (addAnnotationPointCondition()) {
                    if (configsRef.current[actMode]?.overWriteLast) {
                      setAnnotationPoints(a => {
                        if (a.length >= configsRef.current[actMode]?.maxNum) {
                          const newA = [...a]
                          const lastElement = newA.pop()
                          return [...newA, [{ ...lastElement[0], ...relPos }]]
                        } else {
                          return [...a, [{ ...relPos, label: getNextAnnotationLabel(a, !invertedAnnotationColor) }]]
                        }
                      })
                    } else {
                      setAnnotationPoints(a => [...a, [{ ...relPos, label: getNextAnnotationLabel(a, !invertedAnnotationColor) }]])
                    }
                  }
                }
              }
              break;
            default:
              break;
          }
        }

        if (showModify && isItDeleteImageArea(e)) {
          setDeleteImageModalIsActive(true)
        }
        setCurrentMousePosition(null)
      }

    },// eslint-disable-next-line
    [actMode, points, mouseDownPosition, deleteIfInDeleteArea, showModify]
  );

  //Prevent Default event
  const preventDefault = useCallback((e) => {
    e = e || window.event;
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.returnValue = false;
  }, []);
  //Handle Mouse enter in
  const handleMouseEnter = useCallback(() => {
    document.addEventListener("wheel", preventDefault, {
      passive: false,
    });
  }, [preventDefault])
  //Handle mouse leave
  const handleMouseLeave = useCallback((e) => {
    document.removeEventListener("wheel", preventDefault, false);
  }, [preventDefault])


  //Handle pan
  const handlePanStart = useCallback((e) => {
    if (e.pointerType === 'mouse') {
      if (e.srcEvent?.ctrlKey) {
        setZoom((z) => ({ ...z, startPos: { x: z.x, y: z.y } }));
        setControlPan(true)
      }
    }
  }// eslint-disable-next-line 
    , [])

  //Handle pan
  const handlePan = useCallback((e) => {
    if (e.pointerType === 'mouse') {
      if (e.srcEvent?.ctrlKey && controlPanRef.current) {
        setZoom((z) => {
          const width = canvasImageRef.current.width,
            height = canvasImageRef.current.height;
          const scaledWidth = width / z.scale;
          const scaledHeight = height / z.scale;

          const newX = z.startPos.x - e.deltaX / divEventRef.current.clientWidth / z.scale
          const newY = z.startPos.y - e.deltaY / divEventRef.current.clientHeight / z.scale;

          return {
            ...z,
            x: zoomOption?.horizontalPanOut?.state ? getMaxMinXPan(newX, z.scale, width, zoomOption?.horizontalPanOut?.maxXLeft, zoomOption?.horizontalPanOut?.maxXRight, croppingPercent) : Math.min(Math.max(newX, scaledWidth / 2 / width), (width - scaledWidth / 2) / width),
            y: zoomOption?.verticalPanOut?.state ? getMaxMinYPan(newY, z.scale, height, zoomOption?.verticalPanOut?.maxYTop, zoomOption?.verticalPanOut?.maxYBottom, croppingPercent) : Math.min(Math.max(newY, scaledHeight / 2 / height), (height - scaledHeight / 2) / height)
          };
        });
      }

    } else {
      if (highlightedPointRef.current !== null) {
        setMoveHighlightedPoint(true)
      }
      let currentTargetRect =
        canvasImageRef.current.getBoundingClientRect();
      const x = e.center.x - currentTargetRect.left,
        y = e.center.y - currentTargetRect.top;

      const relPos = absoluteToRelativePointPosition({ x, y }, zoomRef.current, canvasImageRef.current);

      switch (actModeRef.current) {
        case MODE.VOLUME_ESTIMATION:
          if (configsRef.current[actModeRef.current]?.enable) {

            if (pointsRef.current.length === 1) {
              //TODO
            }
            else if (configsRef.current[actModeRef.current]?.editable) {
              handleEditPoint(volumeEstimationPointsRef.current, relPos, setVolumeEstimationPoints, false)
            }
          }
          break;
        case MODE.THICKNESS_MEASUREMENT:
          if (configsRef.current[actModeRef.current]?.enable) {

            if (pointsRef.current.length === 1) {
              //TODO
            }
            else if (configsRef.current[actModeRef.current]?.editable) {
              handleEditPoint(measurementPointsRef.current, relPos, setMeasurementPoints, false)
            }
          }
          break;
        case MODE.ANNOTATION:
          if (configsRef.current[actModeRef.current]?.enable) {
            if (configsRef.current[actModeRef.current]?.editable) {
              handleEditPoint(annotationPointsRef.current, relPos, setAnnotationPoints, configsRef.current[actModeRef.current]?.label === ANNOTAION_LABEL_TYPE.RECT ? false : true)
            }
          }
          break;
        default:
          break;
      }
    }
  }// eslint-disable-next-line 
    , [handleEditPoint, zoomOption, croppingPercent])

  //Handle pan end
  const handlePanEnd = useCallback((e) => {
    if (e.pointerType === 'mouse') {
      if (controlPanRef.current) {
        setControlPan(false)
      }
    } else {
      if (moveHighlightedPointRef.current) {
        switch (actModeRef.current) {
          case MODE.VOLUME_ESTIMATION:
            deleteIfInDeleteArea(volumeEstimationPointsRef.current, setVolumeEstimationPoints, volumeEstimationColorsRef.current)
            break;
          case MODE.THICKNESS_MEASUREMENT:
            deleteIfInDeleteArea(measurementPointsRef.current, setMeasurementPoints, measuermentColorsRef.current)
            break;
          case MODE.ANNOTATION:
            deleteIfInDeleteArea(annotationPointsRef.current, setAnnotationPoints, null)
            break;
          default:
            break
        }
        setMoveHighlightedPoint(false);
        setHighlightedPoint(null);

      }
    }// eslint-disable-next-line
  }, [deleteIfInDeleteArea])

  //Handle pinch start
  const handlePinchStart = useCallback(() => {
    setPinching(true)
    setZoom((z) => ({ ...z, scaleStart: z.scale, startPos: { x: z.x, y: z.y } }));
    // eslint-disable-next-line
  }, [])
  //Handle pinch end
  const handlePinchEnd = useCallback(() => {
    setPinching(false)
    // eslint-disable-next-line
  }, [])


  //Handle pinch
  const handlePinch = useCallback(
    (e) => {
      if (zoomOption?.enable) {
        const currentTargetRect =
          canvasImageRef.current.getBoundingClientRect();
        const x =
          (e.center.x - currentTargetRect.left) /
          canvasImageRef.current.clientWidth,
          y =
            (e.center.y - currentTargetRect.top) /
            canvasImageRef.current.clientHeight;

        setZoom((z) => {
          //New Zoom
          let newScale = z.scaleStart * e.scale;
          if (newScale > 1.0) {
            if (newScale > zoomOption.maxZoom) {
              newScale = zoomOption.maxZoom;
            }
          } else {
            newScale = 1.0;
          }

          const newZoom = calculateNewZoom(z, newScale, x, y);
          const width = canvasImageRef.current.width,
            height = canvasImageRef.current.height;
          const scaledWidth = width / newZoom.scale;
          const scaledHeight = height / newZoom.scale;

          const newX = newZoom.startPos.x - e.deltaX / divEventRef.current.clientWidth / newZoom.scale;
          const newY = newZoom.startPos.y - e.deltaY / divEventRef.current.clientHeight / newZoom.scale;

          return {
            ...z,
            ...newZoom,
            x: zoomOption?.horizontalPanOut?.state ? getMaxMinXPan(newX, newZoom.scale, width, zoomOption?.horizontalPanOut?.maxXLeft, zoomOption?.horizontalPanOut?.maxXRight, croppingPercent) : Math.min(Math.max(newX, scaledWidth / 2 / width), (width - scaledWidth / 2) / width),
            y: zoomOption?.verticalPanOut?.state ? getMaxMinYPan(newY, newZoom.scale, height, zoomOption?.verticalPanOut?.maxYTop, zoomOption?.verticalPanOut?.maxYBottom, croppingPercent) : Math.min(
              Math.max(newY, scaledHeight / 2 / height
              ),
              (height - scaledHeight / 2) / height
            )
          }
        });
      }
    },// eslint-disable-next-line
    [zoomOption, calculateNewZoom, croppingPercent]
  );
  //Handle double click
  const handleDoubleClick = useCallback(() => {
    setZoom(DEFAULT_ZOOM);
    // eslint-disable-next-line
  }, [])

  /**
  * Draw image and dimension on canvas
  * @param {Boolean} withGuideLine draw to image the guideline (default: true)
  * @param {Object{x,y}} croppedPercentOfCropped crop percent of corpperecent cropped image: = croppercent -> full image, = {x:1, y:1} - croppedPercent image
  * @param {Boolean} withExtraDraw draw to image the extra draw (extraDrawOnDimLayer) (default: false)
  * @param {Boolean} withDimension draw dimension on image (default: true)
  * @param {Number} scale scale the image with given scale factor (default: 1)
  * @returns canvas
  */
  const getImageAsCanvas = useCallback(async (img = null, croppedPercentOfCropped = croppingPercentRef.current, overlay = null, withGuideLine = drawWithGuideLine, withExtraDraw = false, withDimension = true, scale = 1.0, forceDeafult = false, forceRecalcImageStore = true, targetSizeIn = targetSizeRef.current) => {

    const canvas = document.createElement("canvas");

    const imgWidth = canvasImageRef.current.width / croppedPercentOfCropped.x;
    const imgHeight = canvasImageRef.current.height / croppedPercentOfCropped.y;

    if (!imgWidth || !threeRef.current) {
      return null
    }

    if (imgWidth > imgHeight) {
      canvas.width = imgWidth;
      canvas.height = (targetSizeIn.height / croppedPercentOfCropped.y) / (targetSizeIn.width / croppedPercentOfCropped.x) * imgWidth
    } else {
      canvas.height = imgHeight;
      canvas.width = (targetSizeIn.width / croppedPercentOfCropped.x) / (targetSizeIn.height / croppedPercentOfCropped.y) * imgHeight
    }

    canvas.width = canvas.width * scale;
    canvas.height = canvas.height * scale;
    //TODO CHECK THE CROPPING ISSUE!
    drawImage(img, DEFAULT_CROPPING, DEFAULT_ZOOM, withDimension, false, true, forceDeafult)

    const bitmap = await createImageBitmap(threeRef.current.renderer.domElement)
    canvas.getContext("2d").drawImage(bitmap, 0, 0, canvasImageRef.current.width, canvasImageRef.current.height, 0, 0, canvas.width, canvas.height)

    drawDimensions(canvas, DEFAULT_ZOOM, DEFAULT_CROPPING, false, withDimension, true);//todo
    drawImage(img, croppingPercentRef.current, zoomRef.current, true, true, true)
    bitmap.close();

    if (showOverlayRef.current) {
      drawOverlay(overlay, DEFAULT_ZOOM, DEFAULT_CROPPING)
      canvas.getContext("2d").globalAlpha = overlayOpacity;
      const bitmapOverlay = await createImageBitmap(threeRefOverlay.current.renderer.domElement)
      canvas.getContext("2d").drawImage(bitmapOverlay, 0, 0, bitmapOverlay.width, bitmapOverlay.height, 0, 0, canvas.width, canvas.height)
      drawOverlay()
      canvas.getContext("2d").globalAlpha = 1;
      bitmapOverlay.close();
    }
    if (withExtraDraw) {
      drawExtra(canvas, DEFAULT_ZOOM, DEFAULT_CROPPING, false, withGuideLine, withExtraDraw, true, forceRecalcImageStore)//TODO
    }

    return canvas;
    // eslint-disable-next-line
  }, [drawDimensions, overlayOpacity, showOverlay, targetSize, drawImage, drawExtra])

  /**
   * Download image
   * @param {String} fileName filename to save the image (default: generated_uuid.png)
   * @param {Boolean} withExtraDraw draw to image the extra draw (extraDrawOnDimLayer) (default: false)
   * @param {Boolean} withDimension draw dimension on image (default: true)
   * @param {Number} scale scale the image with given scale factor (default: 1)
   */
  const downloadImage = useCallback(async (fileName = uuidv4() + ".png", withGuideLine = drawWithGuideLine, withExtraDraw = false, withDimension = true, scale = 1.0, forceRecalcImageStore = true, croppedPercentOfCropped = croppingPercentRef.current) => {
    const im = await getImageAsCanvas(imageToDisplay, croppedPercentOfCropped, overlayToDisplay, withGuideLine, withExtraDraw, withDimension, scale, false, forceRecalcImageStore)
    saveAs(im.toDataURL(), fileName);
    // eslint-disable-next-line
  }, [imageToDisplay, overlayToDisplay, getImageAsCanvas])

  /**
  * Get act image as canvas image
  * @param {Boolean} withExtraDraw draw to image the extra draw (extraDrawOnDimLayer) (default: false)
  * @param {Boolean} withDimension draw dimension on image (default: true)
  * @param {Number} scale scale the image with given scale factor (default: 1)
  */
  const getActImageAsCanvas = useCallback(async (withGuideLine = drawWithGuideLine, withExtraDraw = false, withDimension = true, scale = 1.0, forceDeafult = false, forceRecalcImageStore = true, croppedPercentOfCropped = croppingPercentRef.current, targetSizeIn = targetSizeRef.current) => {
    return await getImageAsCanvas(imageToDisplayRef.current, croppedPercentOfCropped, overlayToDisplayRef.current, withGuideLine, withExtraDraw, withDimension, scale, forceDeafult, forceRecalcImageStore, targetSizeIn)
    // eslint-disable-next-line
  }, [imageToDisplay, overlayToDisplay, getImageAsCanvas])

  // It is NOT thickness measurement or volume estimation mode
  const notMeasureOrVolumeEstimation = () => {
    return modesOfDisplayRef.current[0] !== MODE.THICKNESS_MEASUREMENT && modesOfDisplayRef.current[0] !== MODE.VOLUME_ESTIMATION;
  }


  //Get pixelvalues at given rect ->on scaled image
  /**
   * returns x,y positions on canvas and width, height of the canvas if returnPosition is set to true
   * it does not calculate with cropping and zooming if defaultParams is set to true
   * 
   * 
   * Inputs: 
   * X: upper left corner x coord (0-1 scale)
   * Y: upper left corner y coord (0-1 scale)
   * W: width of rect x (0-1 scale)
   * H: height of rect y (0-1 scale)
   * returnPosition: 
   * defaultParams: stands for 
   *    DEFAULT_CROPPING { x: 1, y: 1 } 
   *    DEFAULT_ZOOM { x: 0.5, y: 0.5, scale: 1.0 }
   * 
   */
  const getValuesOfGivenRect = useCallback(async (X, Y, W = null, H = null, returnPosition = false, defaultParams = false) => {//TODO

    const canvas = document.createElement("canvas")
    // create context to draw on its canvas
    const ctx = canvas.getContext("2d")
    if (defaultParams) {    // DEFAULT CROPPING AND DEFAULT ZOOM
      drawImage(imageToDisplay, DEFAULT_CROPPING, DEFAULT_ZOOM, false, false, true, true)

      const tmp = await getActImageAsCanvas(false, false, false, 1.0, true)

      canvas.width = tmp.width
      canvas.height = tmp.height
      ctx.drawImage(tmp, 0, 0, tmp.width, tmp.height, 0, 0, tmp.width, tmp.height)

      W = W === null ? 1 : (W * canvas.width)
      H = H === null ? 1 : (H * canvas.height)
    } else {                // CURRENT CROPPING AND CURRENT ZOOM
      drawImage(imageToDisplay, croppingPercentRef.current, zoomRef.current, false, false, true, true)
      // IMAGE BITMAP
      const bitmap = await createImageBitmap(threeRef.current.renderer.domElement);
      //This is displayed! (current cropping and current zoom ofc)
      drawImage(imageToDisplay, croppingPercentRef.current, zoomRef.current, true, true, true)

      if (canvasImageRef.current.width >= canvasImageRef.current.height) {
        canvas.width = canvasImageRef.current.width
        canvas.height = canvasImageRef.current.width * sizeMMRef.current.height / sizeMMRef.current.width
      } else {
        canvas.height = canvasImageRef.current.height
        canvas.width = canvasImageRef.current.height * sizeMMRef.current.width / sizeMMRef.current.height
      }

      ctx.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height, 0, 0, canvas.width, canvas.height)

      W = W === null ? 1 : (W * canvas.width)
      H = H === null ? 1 : (H * canvas.height)
    }

    if (returnPosition) {
      return [Math.round(X * canvas.width), Math.round(Y * canvas.height), W, H, ctx.getImageData(Math.round(X * canvas.width), Math.round(Y * canvas.height), W, H).data]
    } else {
      return ctx.getImageData(Math.round(X * canvas.width), Math.round(Y * canvas.height), W, H).data
    }
    // eslint-disable-next-line
  }, [drawImage, imageToDisplay, getActImageAsCanvas])

  useEffect(() => {
    if (expandable && !moveHighlightedPoint && notMeasureOrVolumeEstimation()) {
      const imgExpand = new Image()
      imgExpand.onload = () => {
        if (canvasExtraDrawRef?.current?.width) {

          let [modalityHighlightIconSize, modalityHighlightIconMargin] = getTopRightIconSizeAndMargin(canvasExtraDrawRef.current)
          setExpandModalityIcon({
            img: imgExpand,
            x: (canvasExtraDrawRef.current.width - modalityHighlightIconSize - modalityHighlightIconMargin) /
              canvasExtraDrawRef.current.width,
            y: modalityHighlightIconMargin / canvasExtraDrawRef.current.height,
            w: modalityHighlightIconSize / canvasExtraDrawRef.current.width,
            h: modalityHighlightIconSize / canvasExtraDrawRef.current.height
          })
        }
      }
      imgExpand.src = expandModalityIconPath

      const imgCollpase = new Image()
      imgCollpase.onload = () => {
        if (canvasExtraDrawRef?.current?.width) {

          let [modalityHighlightIconSize, modalityHighlightIconMargin] = getTopRightIconSizeAndMargin(canvasExtraDrawRef.current)

          setCollapseModalityIcon({
            img: imgCollpase,
            x: (canvasExtraDrawRef.current.width - modalityHighlightIconSize - modalityHighlightIconMargin) /
              canvasExtraDrawRef.current.width,
            y: modalityHighlightIconMargin / canvasExtraDrawRef.current.height,
            w: modalityHighlightIconSize / canvasExtraDrawRef.current.width,
            h: modalityHighlightIconSize / canvasExtraDrawRef.current.height
          })
        }
      }
      imgCollpase.src = collapseModalityIconPath
    }
    // eslint-disable-next-line 
  }, [])

  //On PC if mouse over a points change pointer
  useEffect(() => {
    if (highlightedPoint !== null && moveHighlightedPoint) {
      document.body.style.cursor = 'grabbing';
    } else if (highlightedPoint !== null) {
      document.body.style.cursor = 'grab';
    } else {
      document.body.style.cursor = ""
    }
  }, [highlightedPoint, moveHighlightedPoint])

  // If length of image in mm is changed (because image size changed) -> update dimension and display size
  useEffect(() => {
    if (targetSize?.width) {
      setDimensionCanvasAndTargetSize(targetSize);
    } // eslint-disable-next-line
  }, [sizeMM]);

  //Redraw image when zoom or image are changed
  useEffect(() => {
    drawImage();
  }, [drawImage]);
  //Redraw overlay when zoom or image are changed
  useEffect(() => {
    drawOverlay();
  }, [drawOverlay]);
  useEffect(() => {
    updateOverlay();
  }, [updateOverlay]);

  //Init canvas size
  useEffect(() => {
    if (initCanvasSize) {
      setCanvasSize(initCanvasSize.width, initCanvasSize.height);
    } // eslint-disable-next-line
  }, []);


  //Add hammer touch and pan listener
  useEffect(() => {
    if (divEventRef.current) {
      const localHammer = new Hammer(divEventRef.current);
      // localHammer.get("pinch").set({ enable: true, });


      var singlepan = new Hammer.Pan({
        event: 'pan',
        direction: Hammer.DIRECTION_ALL,
        threshold: 1,
        pointers: 1
      });

      var pinch = new Hammer.Pinch({
        event: "pinch",
      })

      localHammer.add(singlepan);
      localHammer.add(pinch);


      localHammer.on("doubletap panend panmove panstart ", function (e) {
        if (e.type === "panstart") {
          handlePanStart(e);
        }
        if (e.type === "panmove") {
          handlePan(e);
        }

        if (e.type === "doubletap") {
          handleDoubleClick(e);
        }
        if (e.type === "panend") {
          handlePanEnd(e);
        }

      });

      localHammer.on("pinch pinchstart pinchend", function (ev) {
        if (ev.type === "pinchstart") {
          handlePinchStart(ev);
        }
        if (ev.type === "pinch") {
          handlePinch(ev);
        }
        if (ev.type === "pinchend") {
          handlePinchEnd(ev);
        }
      });



      return () => {
        localHammer.destroy();
      };
    }
  }, [handleDoubleClick, handlePan, handlePanEnd, handlePanStart, handlePinch, handlePinchStart, handlePinchEnd, targetSize]);

  //set mm size on px/mm is changes
  useEffect(() => {
    if (sizePxPMMRef.current?.width !== Infinity && sizePxPMMRef.current?.height !== Infinity && sizePxPMMRef.current?.width > 0 && sizePxPMMRef.current?.height > 0) {
      if (canvasImageRef.current) {
        const width = canvasImageRef.current.width;
        const height = canvasImageRef.current.height;

        setSizeMM(s => {

          if (!s?.width || !s?.height || width / sizePxPMMRef.current?.width !== s?.width ||
            height / sizePxPMMRef.current?.height !== s?.height) {
            return {
              width: width / sizePxPMMRef.current.width,
              height: height / sizePxPMMRef.current.height,
              dimension: sizePxPMMRef.current.dimension,
            }
          } else {
            return s
          }
        });
      }
    }// eslint-disable-next-line
  }, [sizePxPMM])

  //On destroy
  useEffect(() => {
    return () => {
      if (threeRef.current) {
        threeRef.current.material.dispose();
        threeRef.current.geometry.dispose();
        threeRef.current.renderer?.dispose();
      }
    };
    // eslint-disable-next-line
  }, []);

  //Calc disntace on measurement change
  useEffect(() => {
    calculateDistances(measurementPoints, setCurrentDistances)
    // eslint-disable-next-line
  }, [measurementPoints, calculateDistances])

  //Calc disntace on volume estimation change
  useEffect(() => {
    calculateDistances(volumeEstimationPoints, setCurrentVEDistances)
    // eslint-disable-next-line
  }, [volumeEstimationPoints, calculateDistances])


  return {
    imageCanvas: (
      <Box
        onClick={(e) => {

          if (showOverlay && !e.ctrlKey && overlayTypeRef.current === OVERLAY_TYPE.SEGMENTATION) {
            let currentTargetRect =
              canvasOverlayRef.current.getBoundingClientRect();
            const x = e.clientX - currentTargetRect.left,
              y = e.clientY - currentTargetRect.top;
            const relPos = absoluteToRelativePointPosition({ x, y }, zoomRef.current, canvasOverlayRef.current);
            relPos.x = relPos.x * croppingPercent.x
            relPos.y = relPos.y * croppingPercent.y
            const X = Math.round(relPos.x * overlayToDisplay.width)
            const Y = Math.round(relPos.y * overlayToDisplay.height)
            const ind = overlayToDisplay.width * Y + X

            const layer = Math.floor(overlayToDisplay.data[ind] * 255)
            setActiveLayerOfOverlay(s => s === layer ? 0 : layer)
          }
        }}
        style={{ visibility: !firstRender ? "hidden" : "visible", background: isActiveBorder ? secondary80 : "", height: (targetSize.height + 2 * imageMargin) || 0, borderRadius: "20px", }}>
        <Box
          className={style.ContainerDiv}
          style={{ margin: `${imageMargin}px`, height: targetSize.height || 0 }}
        >


          <canvas
            ref={divEventRef}
            style={{ ...targetSize, borderRadius: "20px", zIndex: layerOrder.EVENT, opacity: grayedOut, background: neutral40 }}
            className={style.EventLayer}
            onDoubleClick={handleDoubleClick}
            onWheelCapture={handleWheel}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={onMouseMoveHandle}
            onMouseDown={onMouseDownHandle}
            onMouseUp={onMouseUpHandle}
          />

          {showOverlay && (overlayType === OVERLAY_TYPE.SEGMENTATION) && <Box
            sx={{
              zIndex: layerOrder.LABEL,
              marginTop: `${targetSize.height * measuermentDrawStartOnDisplay?.y}px`,
              marginLeft: `${MEASUREMENT_BOX_PADDING / canvasExtraDrawRef.current?.width * targetSize.width/*targetSize.width * (1 - MEASUREMENT_POSITION_X_PERCENT)*/}px`, //paddingLeft: "5px", paddingRight: "5px",
              height: targetSize.height * measuermentDrawStartOnDisplay?.height,
              width: targetSize.width - /*targetSize.width * (1 - MEASUREMENT_POSITION_X_PERCENT)*/MEASUREMENT_BOX_PADDING / canvasExtraDrawRef.current?.width * targetSize.width -
                (measuermentDrawStartOnDisplay?.x ? (targetSize.width * (1 - measuermentDrawStartOnDisplay?.x)) : (MEASUREMENT_BOX_PADDING / canvasExtraDrawRef.current?.width * targetSize.width))
            }}
            className={style.Label}>

            <SegmentationLabel width="100%" height="100%" activeLayer={activeLayerOfOverlay} overlayExtraData={overlayExtraData} />
          </Box>}
          <canvas
            ref={canvasExtraDrawRef}
            style={{ ...targetSize, borderRadius: "20px", zIndex: layerOrder.EXTRA }}
            className={style.ExtraLayer}
          />

          <canvas
            ref={canvasDimensionRef}
            style={{ ...targetSize, borderRadius: "20px", zIndex: layerOrder.DIM }}
            className={style.DrawLayer}
          />


          <canvas
            ref={canvasOverlayRef}
            style={{
              borderRadius: "20px", zIndex: layerOrder.OVERLAY, visibility: showOverlay ? "visible" : "hidden", opacity: overlayOpacity,
              ...targetSize, //width: targetSize.width * (overlayType === OVERLAY_TYPE.LINE_DOPPLER ? 0.01 : 1), position: "absolute", left: overlayType === OVERLAY_TYPE.LINE_DOPPLER ? (targetSize.width / 2 - targetSize.width * 0.005 - zoom.scale * targetSize.width * (zoom.x - 0.5)) : 0, top: 0

            }}// imageRendering: "pixelated" }}
            className={style.Overlay}
          />
          <canvas
            ref={canvasImageRef}
            style={{ ...targetSize, borderRadius: "20px", zIndex: layerOrder.IMAGE }}
            className={style.Imlayer}
          />

        </Box>
      </Box >
    ),

    drawImageWithActParams: (drawDimension = true) => drawImage(imageToDisplay, croppingPercent, zoomRef.current, true, drawDimension),
    drawImageGivenData: (img) => drawImage(img, croppingPercentRef.current, zoomRef.current, true, false),
    setFreeText,
    freeTextRef,
    canvasDimensionRef,
    canvasImageRef,
    canvasOverlayRef,
    sizePxPMM,
    sizePxPMMRef,
    sizeMM,
    sizeMMRef,
    setImageToDisplay,
    imageToDisplay,
    targetSize,
    targetSizeRef,
    setTargetSize: setDimensionCanvasAndTargetSize,
    setCanvasSize,
    setCanvasSizeVar,
    setExtraDrawOnDimLayer,
    currentVerticalDistance: currentDistances.dy,
    currentHorizontalDistance: currentDistances.dx,
    currentDistance: currentDistances.d,
    currentVerticalVEDistance: currentVEDistances.dy,
    currentHorizontalVEDistance: currentVEDistances.dx,
    currentVEDistance: currentVEDistances.d,
    calculateDistances,
    setCurrentMousePosition,
    points,
    pointsRef,
    setPoints,
    setAnnotationPoints,
    setMeasurementPoints,
    setVolumeEstimationPoints,
    setMeasuermentColors,
    setVolumeEstimationColors,
    measurementPointsRef,
    measurementPoints,
    volumeEstimationPointsRef,
    volumeEstimationPoints,
    annotationPointsRef,
    annotationPoints,
    reset,
    setCurrentDistances,
    currentVEDistances,
    currentVEDistancesRef,
    setCurrentVEDistances,
    downloadImage,
    getActImageAsCanvas,
    setPxPMM,
    croppingPercent,
    croppingPercentRef,
    setCroppingPercent,
    actMode,
    setActMode,
    setModesOfDisplay,
    modesOfDisplay,
    canvasSizeVar,
    sizeInPx,
    sizeInPxRef,
    setOverlayCanvasSize,
    setOverlayToDisplay,
    setShowOverlay,
    showOverlay,
    showOverlayRef,
    getImageAsCanvas,
    firstRender,
    setFirstRender,
    message,
    setMessage,
    setShowColorbar,
    showColorbar,
    setIsActiveBorder,
    measuermentColorsRef,
    volumeEstimationColorsRef,
    configs,
    setConfigs,
    setApplycolormap,
    applyColormap,
    applyColormapRef,
    zoom,
    storedLastImageRef,
    zoomRef,
    setZoom,
    setGpuFormat,
    setGpuType,
    setExtraGPUParams,
    extraGPUParams,
    extraGPUParamsRef,
    editing: !!moveHighlightedPointRef.current,
    setPresetZoom: (z, apply = true) => { setDefaultZoom(z); if (apply) { setZoom(z) } },
    setGrayedOut,
    getValuesOfGivenRect,
    setOverlayExtraData,
    extraDrawOnDimLayer,
    threeRef,
    activeLayerOfOverlayRef,
    activeLayerOfOverlay,
    overlayExtraDataRef,

    modalityHighlight,
    setModalityHighlight,

    setShowVolumeEstimation,
    showVolumeEstimationRef,

    deleteImageModalIsActive,
    setDeleteImageModalIsActive,
    showModify,
    showModifyRef,
    setShowModify,
    threeRefOverlay,
    drawGivenOverlayData: (img, set = true) => { drawOverlay(img, zoomRef.current, croppingPercentRef.current); /*if (set) { setOverlayToDisplay(img) }*/ },
    setLayerOrder,
    overlayType,
    overlayTypeRef,
    setOverlayType
  };
};

export default useImage;
